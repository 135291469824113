<template>
    <div>
        <b-sidebar id="sidebar-backdrop" :backdrop-variant="'dark'" :title="'Add Inspection'" backdrop shadow
            :visible="allowAddInspection" :right="true" no-header-close no-close-on-backdrop no-close-on-esc>
            <div class="d-block text-center mt-3">
                <b-row class="align-items-center px-4">
                    <h3 class="f-18 font-weight-bold col-12 text-left">Basic</h3>
                    <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="Record # :" class="text-left mb-0" v-if="showRecordField">
                            <b-form-input v-model="inspectionPayload.record_no" type="text" placeholder="Enter Record"
                                class="unit" required></b-form-input>
                            <p v-if="errors && errors.record_no" class="field-error">
                                {{ errors.record_no[0] }}
                            </p>
                        </b-form-group>
                        <b-form-group label="Record # :" class="text-left mb-0" v-else>
                            <b-form-input v-model="lastRecord" disabled type="text" placeholder="Enter Record" class="unit"
                                required></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <div class="text-left select_arrow ">
                            <label class="state">Status</label>
                            <b-form-select v-model="inspectionPayload.status" :options="[{
                                value: 'New',
                                text: 'New'
                            },
                            {
                                value: 'Open',
                                text: 'Open'
                            },
                            {
                                value: 'Closed',
                                text: 'Closed'
                            },
                            {
                                value: 'Urgent',
                                text: 'Urgent'
                            }
                            ]" />
                        </div>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label for="inspection-add-datepicker" class="state">Date:</label>
                            <date-picker v-model="inspectionPayload.date" :formatter="momentFormat"
                            :range-separator="' - '" @clear="inspectionPayload.date = ''" placeholder="Select Date"
                            class="date-picker-custom" :teleport="'body'"></date-picker>
                        <p v-if="errors && errors.date" class="field-error">
                            {{ errors.date[0] }}
                        </p>
                    </b-col>
                    <b-col md="3" sm="12" class="text-left mt-3">
                        <label class="state">Start time:</label>
                        <b-form-input type="time" class="f-12" v-model="inspectionPayload.start_time"></b-form-input>
                    </b-col>
                    <b-col md="3" sm="12" class="text-left mt-3">
                        <label class="state">End time:</label>
                        <b-form-input type="time" class="f-12" v-model="inspectionPayload.end_time"></b-form-input>
                    </b-col>
                    <b-col md="6" sm="12">
                        <div class="text-left select_arrow mt-3">
                            <label class="state">Driver:</label>
                            <v-select class="bg_white w-100 f-12" v-model="inspectionPayload.driver"
                                :options="driverOptions" item-value="id" item-text="label" :reduce="(option) => option.id"
                                @search="searchDriver">
                                <span slot="no-options">
                                    Search Driver ...
                                </span>
                            </v-select>
                            <p v-if="errors && errors.driver" class="field-error">
                                {{ errors.driver[0] }}
                            </p>
                        </div>
                    </b-col>
                    <b-col md="3" sm="12">
                        <div class="text-left select_arrow mt-3">
                            <label class="state">Vehicle:</label>
                            <v-select class="bg_white w-100 f-12" v-model="inspectionPayload.vehicle"
                                :options="vehicleOptions" item-value="id" item-text="label" :reduce="(option) => option.id"
                                @search="searchVehicle">
                                <span slot="no-options">
                                    Search Vehicle ...
                                </span>
                            </v-select>
                        </div>
                    </b-col>
                    <b-col md="3" sm="12">
                        <div class="text-left select_arrow mt-3">
                            <label class="state">Trailer:</label>
                            <v-select class="bg_white w-100 f-12" v-model="inspectionPayload.trailer"
                                :options="trailerOptions" item-value="id" item-text="label" :reduce="(option) => option.id"
                                @search="searchTrailer">
                                <span slot="no-options">
                                    Search Trailer ...
                                </span>
                            </v-select>
                        </div>
                    </b-col>
                    <b-col lg="6" md="12">
                        <div class="text-left select_arrow mt-3">
                            <label class="state">Level:</label>
                            <b-form-select v-model="inspectionPayload.level" class="f-12" :options="[{
                                value: null,
                                text: ''
                            },
                            {
                                value: 'I',
                                text: '|'
                            },
                            {
                                value: 'II',
                                text: '||'
                            },
                            {
                                value: 'III',
                                text: '|||'
                            }
                            ]" />
                        </div>
                    </b-col>
                    <b-col md="3" sm="12" class="text-left mt-3">
                        <label class="state">Report #:</label>
                        <b-form-input type="text" v-model="inspectionPayload.report_number" class="f-12"
                            placeholder="Enter Report #"></b-form-input>
                    </b-col>
                    <b-col md="3" sm="12">
                        <div class="text-left select_arrow mt-3">
                            <label class="state">State:</label>
                            <v-select class="bg_white w-100" v-model="inspectionPayload.state" :options="states"
                                item-value="id" item-text="label" :reduce="(option) => option.id">
                            </v-select>
                        </div>
                    </b-col>
                    <b-col md="12" class="text-left mb-7rem mt-3">
                        <label class="state text-left">Description:</label>
                        <b-form-textarea id="textarea" placeholder="Enter Notes" class="f-12" rows="5"
                            v-model="inspectionPayload.notes"></b-form-textarea>
                    </b-col>
                </b-row>
                <div class="sidebar-footer position-fixed">
                    <div class="text-right modal-footer border-0 p-0">
                        <b-button class="mx-0 mr-3 h-40px w-100px" @click="resetPopup()"
                            variant="secondary">Cancel</b-button>
                        <b-button class="mx-0 h-40px w-125px px-2 mr-3" @click="onAddNewInspection(true)"
                            variant="outline-primary">Save & Open</b-button>
                        <b-button class="mx-0 h-40px w-100px" @click="onAddNewInspection()"
                            variant="primary">Save</b-button>
                    </div>
                </div>
            </div>
        </b-sidebar>
    </div>
</template>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import * as moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
    components: { vSelect, DatePicker },
    name: "AddInspection",
    props: {
        allowAddInspection: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        ...mapGetters([
            "allCountries",
            "userCompany",
            "allEquipments",
            "allDrivers",
        ]),
    },
    data() {
        return {
            errors: {},
            states: [],
            showRecordField:false,
            momentFormat: {
                stringify: (date) => {
                    return date ? moment(date).format('MMM D, YYYY') : ''
                },
                parse: (value) => {
                    return value ? moment(value, 'MMM D, YYYY').toDate() : null
                }
            },
            lastRecord: null,
            inspectionPayload: {
                record_no: "",
                driver: null,
                status: "New",
                vehicle: null,
                trailer: null,
                date: null,
                start_time: null,
                end_time: null,
                level: null,
                state: "",
                report_number: '',
                notes: ""
            },
            driverOptions: [],
            vehicleOptions: [],
            trailerOptions: []
        }
    },
    methods: {
        ...mapActions([
            "getAllCountries",
            "getAllEquipments",
            "getAllDrivers",
            "setLoader",
            "addDotInspection",
            "setNotification",
            "getLastRecord"
        ]),
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        async onAddNewInspection(openInspection = false) {
            try {
                const nullKeys = this.checkNullValues({
                    driver: this.inspectionPayload.driver,
                    date: this.inspectionPayload.date,
                    ...this.showRecordField ? { record_no: this.inspectionPayload.record_no } : {}
                });
                if (nullKeys.length === 0) {
                    this.setLoader(true);
                    if (!this.showRecordField) delete this.inspectionPayload.record_no
                    const record = await this.addDotInspection({ ...this.inspectionPayload, company: this.userCompany, date: moment(this.inspectionPayload.date).format('YYYY-MM-DD') });
                    await this.setNotification({
                        msg: `Dot Inspection Successfully Added`,
                        type: "success",
                        color: "green",
                    });
                    this.setLoader(false);
                    if (openInspection) this.$emit("inspectionAdded", record)
                    else this.$emit("inspectionAdded", null)
                    this.resetPopup()
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
                this.setLoader(false);
            }

        },
        resetPopup() {
            this.inspectionPayload = {
                record_no: "",
                driver: null,
                status: "New",
                vehicle: null,
                trailer: null,
                date: null,
                start_time: null,
                end_time: null,
                level: null,
                state: "",
                report_number: '',
                notes: ""
            },
                this.errors = {}
            this.vehicleOptions = [],
                this.trailerOptions = [],
                this.claimOptions = []
            this.$emit('close');
        },
        async searchDriver(searchText) {
            let options = []
            const url = `name=${searchText}&page=1&page_size=10`
            await this.getAllDrivers(url);
            this.allDrivers.forEach((x) => {
                options.push({
                    id: x.id,
                    label: `${x.user.first_name} ${x.user.last_name}`,
                });
            });
            this.driverOptions = this.driverOptions.concat(options)
            this.driverOptions = [...new Map(this.driverOptions.map(item => [item['id'], item])).values()]
        },
        async searchVehicle(searchText) {
            let options = [];
            const url = `unit_no=${searchText}&type=Vehicle&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
            await this.getAllEquipments(url);
            this.allEquipments.forEach((x) => {
                options.push({
                    id: x.id,
                    label: x.unit_no,
                });
            });
            this.vehicleOptions = this.vehicleOptions.concat(options)
            this.vehicleOptions = [...new Map(this.vehicleOptions.map(item => [item['id'], item])).values()]
        },
        async searchTrailer(searchText) {
            let options = [];
            const url = `unit_no=${searchText}&type=Trailer&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
            await this.getAllEquipments(url);
            this.allEquipments.forEach((x) => {
                options.push({
                    id: x.id,
                    label: x.unit_no,
                });
            });
            this.trailerOptions = this.trailerOptions.concat(options)
            this.trailerOptions = [...new Map(this.trailerOptions.map(item => [item['id'], item])).values()]
        },
        async getCountries() {
            await this.getAllCountries();
            const countries = this.allCountries.map((item) => ({
                id: item.id,
                label: item.name,
            }));
            this.getStates(countries[0]);
        },
        getStates(selectedOption) {
            this.states = this.allCountries
                .filter((country) => country.id === selectedOption.id)[0]
                .state.map((item) => ({
                    id: item.id,
                    label: item.name,
                }));
            this.inspectionPayload.state = this.states.filter(
                (state) => state.label === "-"
            )[0].id;
        }
    },
    watch: {
        async allowAddInspection() {
            if(this.allowAddInspection){
                this.setLoader(true)
            const lastItemRecord = await this.getLastRecord({
                company_id: this.userCompany,
                query: `model_name=safetyinspection`
            })
            if (lastItemRecord) this.lastRecord = lastItemRecord + 1;
            else this.showRecordField = true
            this.setLoader(false)
        }
        }
    },
    async mounted() {
        await this.getCountries();
    },
}
</script>