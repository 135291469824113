<template>
  <div class="header bg-white d-flex justify-content-end align-items-center position-fixed">
    <!-- <div class="">
      <b-icon icon="bell" class="icon rounded"></b-icon>
      <b-badge class="badge rounded-circle">4</b-badge>
    </div> -->
    <div v-if="user" class="user-detail d-flex align-items-center">
      <!-- <div class="my-auto position-relative">
        <svg width="15" height="15" v-if="user && appVersion && user.version !== appVersion" class="update-notification-dot" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="7.5" cy="7.5" r="6.5" fill="#FF0000" stroke="white" stroke-width="2"/>
</svg>

        <img :src="require('@/assets/help.svg')" class="cursor-pointer" @click="$bvModal.show('help-modal')" width="25"
          height="25" />
      </div> -->
      <NotificationHeader :bellIcon="'black'"/>
      <b-dropdown id="dropdown-right" right class="mx-2 dropdown header-section-dropdown">
        <template slot="button-content">
          <b-img v-if="user.team_member && user.team_member.profile_picture" class="userSmallImage rounded-circle"
            :src="user.team_member.profile_picture"></b-img>
          <div v-else class="user-default-img">
            {{ user.first_name.charAt(0).toUpperCase() }}{{ user.last_name.charAt(0).toUpperCase() }}
          </div>
        </template>
        <b-dropdown-item><span class="font-weight-bold f-14">{{ user.first_name }} {{ user.last_name }}</span>
          <br /> <span class="f-12">{{ user.email }}</span>
        </b-dropdown-item>
        <hr class="my-2" />
        <b-dropdown-item to="/profile-page"><span class="f-14">View Profile</span></b-dropdown-item>
        <b-dropdown-item to="/notifications"><span class="f-14">View Notifications</span></b-dropdown-item>
        <b-dropdown-item to="/all-companies"><span class="f-14">View Companies</span></b-dropdown-item>
        <hr class="my-2" />
        <b-dropdown-item @click="OnlogoutUser"><span class="f-14">Logout</span></b-dropdown-item>
      </b-dropdown>
    </div>
    <Help />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Help from "@/components/Help";
import NotificationHeader from "../Shared/NotificationHeader.vue";
export default {
  name: "Header",
  components: { Help, NotificationHeader },
  computed: mapGetters(["user", 'appVersion', 'userNotifications']),
  methods: {
    ...mapActions(["logoutUser", "getUserByID"]),
    OnlogoutUser() {
      this.logoutUser();
      this.$intercom.shutdown()
      this.$router.push("/login");
    },
  },
  async mounted() {
    await this.getUserByID();
  },
};
</script>

<style>
.update-notification-dot {
  position: absolute;
  top: -2px;
  right: -5px;
}

.header-section-dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
}

.userSmallImage {
  width: 35px;
  height: 35px;
  border: 2px solid #FAFAFA;
  object-fit: cover;
}

.user-default-img {
  width: 35px;
  height: 35px;
  background-color: #2cce89;
  border: 2px solid #FAFAFA;
  color: white;
  display: flex;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.user-default-img-profile-section {
  width: 100px;
  height: 100px;
  font-size: 20px;
}

.header {
  padding: 10px 0;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  background: #fff;
  border: 1px solid #dee2e6;
  z-index: 6;
}

.icon {
  width: 22px;
  height: 25px;
  font-size: 24px;
  color: var(--black);
  cursor: pointer;
}

.b-icon.icon :not(svg) {
  color: #000000;
}

.badge {
  font-size: 10px !important;
  height: 17px;
  width: 17px;
  background-color: var(--btn-bg-color);
  position: relative;
  top: -13px !important;
  left: -11px;
  padding: 3px !important;
  line-height: 11px !important;
}

#dropdown-right button {
  background-color: transparent !important;
  color: var(--black) !important;
  font-weight: 600;
  font-size: var(--small);
  border: unset;
  border: none !important;
}

#dropdown-right .dropdown-toggle::after {
  margin-left: 10px;
}

.dropdown-menu {
  margin-top: 5px !important;
}

.notification-dropdown>button::after {
  border: 0 !important;
}

.notification-dropdown .dropdown-menu {
  margin-top: 5px !important;
  width: 300px;
  transform: translate3d(-180px, 25px, 0px) !important;
  padding: 0;
  border-radius: 0.25rem;
}

.notification-header-section {
  background-color: #F5F5F5;
  border-radius: 0.25rem 0.25rem 0rem 0rem !important;
}

/* @media (max-width: 575px) {
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    padding: 0 !important;
  }
} */
/* @media(max-width: 370px){
    .user-detail{
        width: min-content;
    }
} */
</style>
