<template>
    <b-form @submit.prevent="createCompany">
        <b-row>
            <b-col sm="12">
                <b-form-group label="Company Name:" class="text-left label">
                    <b-form-input type="text" placeholder="Enter Company Name" class="input h-50px" required
                        v-model="company.company_name"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="12">
                <v-app>
                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <label class="text-left w-fit-content" v-on="on" v-bind="attrs">Company ID:</label>
                        </template> This is required for drivers to login into mobile app. Comp ID can not be changed.
                    </v-tooltip>
                </v-app>
                <b-form-input type="text" placeholder="Enter Company ID" class="input h-50px" required
                    v-model="company.company_id"></b-form-input>
                <p v-if="errors && errors.company_id" class="field-error text-left mb-4">
                    {{ errors.company_id[0] }}
                </p>
            </b-col>
        </b-row>

        <b-form-group label="Address:" label-for="input-1" class="mt-3 text-left label text-capitalize">
            <b-form-input type="text" class="mb-3 input h-50px" placeholder="Enter Address" v-model="company.address"
                required></b-form-input>
        </b-form-group>

        <b-form-group label="City:" label-for="input-1" class="text-left label text-capitalize">
            <b-form-input type="text" class="mb-3 input h-50px" placeholder="Enter City" v-model="company.city"
                required></b-form-input>
        </b-form-group>
        <b-form-group label="State:" label-for="input-1" class="text-left label text-capitalize">
            <v-select class="bg_white w-100 h-50px" :options="states" item-value="id" item-text="label"
                :reduce="(option) => option.id" v-model="company.state">
                <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!company.state" v-bind="attributes" v-on="events" />
                </template>
            </v-select>
        </b-form-group>
        <b-form-group label="Postal Code:" label-for="input-1" class="text-left label text-capitalize">
            <b-form-input type="text" class="mb-3 input h-50px" placeholder="Enter Postal Code" v-model="company.zipcode"
                required></b-form-input>
        </b-form-group>
        <b-form-group label="Phone Number:" label-for="input-1" class="text-left label text-capitalize">
            <vue-tel-input v-bind="bindProps" v-model="company.phone_number" class="w-100 f-14 h-50px"
                @validate="checkNum"></vue-tel-input>
        </b-form-group>
        <b-button type="submit" class="w-100 mt-3 login h-50px" variant="primary">Register</b-button>
    </b-form>
</template>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapActions, mapGetters } from "vuex";
export default {
    name: "Step2",
    props: ['company', 'createUserCompany'],
    data() {
        return {
            states: [],
            countries: [],
            isPhoneValid: false,
            errors: {},
            bindProps: {
                mode: "international",
                autoFormat: true,
                required: true,
                preferredCountries: ["US", "CA", "MX"],
                inputOptions: {
                    placeholder: "Enter a phone no.",
                    maxlength: 30,
                    required: true,
                },
                dropdownOptions: {
                    showSearchBox: true,
                    showDialCodeInList: true,
                    showFlags: true,
                    showDialCodeInSelection: true,
                    width: "300px",
                },
            }
        }
    },
    components: { vSelect },
    computed: {
        ...mapGetters(["allCountries"])
    },
    methods: {
        ...mapActions(["getAllCountries"]),
        createCompany() {
            try {
                const regex = /^[A-Za-z0-9-]{3,}$/;
                const isCompanyIDValid = regex.test(this.company.company_id);
                if (isCompanyIDValid) {
                    this.createUserCompany()
                    this.errors = {}
                }
                else {
                    let error = {};
                    error['company_id'] = ["Only letters, numbers, and hyphens are allowed, no spaces, and at least 3 characters are required."];
                    throw error;
                }
            }
            catch (error) {
                if (typeof error === "object") {
                    this.errors = error;
                }
            }
        },
        checkNum(phoneObject) {
            if (phoneObject.valid !== undefined) {
                this.isPhoneValid = phoneObject.valid;
            }
        },
        async getCountries() {
            await this.getAllCountries();
            this.countries = this.allCountries.map((item) => ({
                id: item.id,
                label: item.name,
            }));
            this.getStates(this.countries[0]);
        },
        getStates(selectedOption) {
            this.states = this.allCountries
                .filter((country) => country.id === selectedOption.id)[0]
                .state.map((item) => ({
                    id: item.id,
                    label: item.name,
                }));
            this.$emit("getStates", this.states)
        },
    },
    async mounted() {
        await this.getCountries();
    },
}
</script>