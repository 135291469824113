<template>
  <div>
    <div class="">
      <header class="text-left search_equipment d-flex user-detail row">
        <div class="col-md-8 d-flex justify-content-between mx-3 mx-md-auto align-items-center">
          <b-img :src="require('@/assets/form-logo.png')" width="143" height="37"></b-img>
          <div v-if="userRole && userRole === 'Driver'">
            <b-dropdown id="dropdown-right" right class="mx-2 dropdown dropdown-dark-section header-section-dropdown">
              <template slot="button-content">
                <b-img v-if="user.team_member && user.team_member.profile_picture" class="userSmallImage rounded-circle" :src="user.team_member.profile_picture"></b-img>
            <div v-else class="user-default-img">
              {{ user.first_name.charAt(0).toUpperCase() }}{{ user.last_name.charAt(0).toUpperCase() }}
            </div>
              </template>
              <b-dropdown-item><span class="font-weight-bold f-14">{{ user.first_name }} {{ user.last_name }}</span>
                <br /> <span class="f-12">{{ user.email }}</span>
              </b-dropdown-item>
              <div v-if="userRole !== 'Driver'">
              <hr class="my-2" />
              <b-dropdown-item to="/profile-page"><span class="f-14">View Profile</span></b-dropdown-item>
              <b-dropdown-item to="/notifications"><span class="f-14">View Notifications</span></b-dropdown-item>
              <b-dropdown-item to="/all-companies"><span class="f-14">View Companies</span></b-dropdown-item>
              </div>
              <hr class="my-2" />
              <b-dropdown-item @click="OnlogoutUser"><span class="f-14">Logout</span></b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </header>
      <div class="row">
        <div class="col-md-8 mx-3 mx-md-auto pt-64">
          <div class="text-left mt-5" v-if="showDriversData">
            <h2 class="font-weight-bold mb-3">
              Hello
              {{
                allDriverDocs.driver &&
                allDriverDocs.driver.user &&
                allDriverDocs.driver.user.first_name
              }}!
            </h2>
            <div class="driver-detail-section" v-if="driverDocs.length > 0">
              <div class="font-weight-bold f-16 mb-3 detail-section bg-white">
                {{ allDriverDocs.driver && allDriverDocs.driver.name }}
              </div>
              <a class="detail-section bg-white mb-3" :href="docs.document" target="_blank"
                v-for="(docs, index) in driverDocs" :key="index">
                <div>
                  <p class="f-14 mb-0 text-left font-weight-bold mb-1">
                    {{ docs.description ? docs.description : "-" }}
                  </p>
                  <p class="f-12 mb-0 text-left" :class="docs.expired_status">
                    {{
                      docs.expiry_date
                      ? "Expiring " +
                      moment(docs.expiry_date).format("MMM DD, YYYY")
                      : "-"
                    }}
                  </p>
                </div>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_4849_341)">
                    <path
                      d="M10 0C4.48582 0 0 4.48582 0 10C0 15.5142 4.48582 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48582 15.5142 0 10 0ZM13.0892 10.5892L8.9225 14.7558C8.76 14.9183 8.54668 15 8.33332 15C8.12 15 7.90664 14.9183 7.74414 14.7558C7.41832 14.43 7.41832 13.9033 7.74414 13.5775L11.3217 10L7.74418 6.4225C7.41836 6.09668 7.41836 5.57 7.74418 5.24418C8.07 4.91836 8.59668 4.91836 8.9225 5.24418L13.0892 9.41086C13.415 9.73668 13.415 10.2633 13.0892 10.5892Z"
                      fill="#2D69F6" />
                  </g>
                  <defs>
                    <clipPath id="clip0_4849_341">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
            <div class="driver-detail-section mt-5" v-if="vehicleDocs.length > 0">
              <div class="mb-3 detail-section bg-white justify-content-between"
                :class="userRole === 'Driver' ? 'cursor-pointer' : ''"
                v-on="userRole === 'Driver' ? { click: () => $router.push('/driver-equipment-details/' + allDriverDocs.driver.default_vehicle.id) } : {}">
                <span class="font-weight-bold f-16">
                  {{
                    allDriverDocs.driver &&
                    allDriverDocs.driver.default_vehicle &&
                    allDriverDocs.driver.default_vehicle.unit_no
                  }} - Vehicle
                </span>
                <p @click.stop="$router.push('/driver-vehicles-search')" class="text_primary f-14 mb-0"
                  v-if="userRole === 'Driver' && !user.team_member.lock_vehicle">View All</p>
              </div>
              <a class="detail-section bg-white mb-3" :href="docs.document" target="_blank"
                v-for="(docs, index) in vehicleDocs" :key="index">
                <div>
                  <p class="f-14 font-weight-bold mb-1">
                    {{ docs.description ? docs.description : "-" }}
                  </p>
                  <p class="f-12 mb-0 text-left" :class="docs.expired_status">
                    {{
                      docs.expiry_date
                      ? "Expiring " +
                      moment(docs.expiry_date).format("MMM DD, YYYY")
                      : "-"
                    }}
                  </p>
                </div>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_4849_341)">
                    <path
                      d="M10 0C4.48582 0 0 4.48582 0 10C0 15.5142 4.48582 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48582 15.5142 0 10 0ZM13.0892 10.5892L8.9225 14.7558C8.76 14.9183 8.54668 15 8.33332 15C8.12 15 7.90664 14.9183 7.74414 14.7558C7.41832 14.43 7.41832 13.9033 7.74414 13.5775L11.3217 10L7.74418 6.4225C7.41836 6.09668 7.41836 5.57 7.74418 5.24418C8.07 4.91836 8.59668 4.91836 8.9225 5.24418L13.0892 9.41086C13.415 9.73668 13.415 10.2633 13.0892 10.5892Z"
                      fill="#2D69F6" />
                  </g>
                  <defs>
                    <clipPath id="clip0_4849_341">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
            <div class="driver-detail-section mt-5" v-else>
              <div class="mb-3 detail-section bg-white justify-content-between">
                <span class="font-weight-bold f-16">
                  N/A - Vehicle
                </span>
                <router-link to="/driver-vehicles-search" class="text_primary f-14"
                  v-if="userRole === 'Driver' && !user.team_member.lock_vehicle">View All</router-link>
              </div>
            </div>
            <div class="driver-detail-section mt-5" v-if="trailerDocs.length > 0">
              <div class="mb-3 detail-section bg-white justify-content-between"
                :class="userRole === 'Driver' ? 'cursor-pointer' : ''"
                v-on="userRole === 'Driver' ? { click: () => $router.push('/driver-equipment-details/' + allDriverDocs.driver.default_trailer.id) } : {}">
                <span class="font-weight-bold f-16">
                  {{
                    allDriverDocs.driver &&
                    allDriverDocs.driver.default_trailer &&
                    allDriverDocs.driver.default_trailer.unit_no
                  }} - Trailer
                </span>
                <p @click.stop="$router.push('/driver-trailers-search')" class="text_primary f-14 mb-0"
                  v-if="userRole === 'Driver' && !user.team_member.lock_vehicle">View All</p>
              </div>
              <a class="detail-section bg-white mb-3" :href="docs.document" target="_blank"
                v-for="(docs, index) in trailerDocs" :key="index">
                <div>
                  <p class="f-14 mb-1 font-weight-bold">
                    {{ docs.description ? docs.description : "-" }}
                  </p>
                  <p class="f-12 mb-0 text-left" :class="docs.expired_status">
                    {{
                      docs.expiry_date
                      ? "Expiring " +
                      moment(docs.expiry_date).format("MMM DD, YYYY")
                      : "-"
                    }}
                  </p>
                </div>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_4849_341)">
                    <path
                      d="M10 0C4.48582 0 0 4.48582 0 10C0 15.5142 4.48582 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48582 15.5142 0 10 0ZM13.0892 10.5892L8.9225 14.7558C8.76 14.9183 8.54668 15 8.33332 15C8.12 15 7.90664 14.9183 7.74414 14.7558C7.41832 14.43 7.41832 13.9033 7.74414 13.5775L11.3217 10L7.74418 6.4225C7.41836 6.09668 7.41836 5.57 7.74418 5.24418C8.07 4.91836 8.59668 4.91836 8.9225 5.24418L13.0892 9.41086C13.415 9.73668 13.415 10.2633 13.0892 10.5892Z"
                      fill="#2D69F6" />
                  </g>
                  <defs>
                    <clipPath id="clip0_4849_341">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
            <div class="driver-detail-section mt-5" v-else>
              <div class="mb-3 detail-section bg-white justify-content-between">
                <span class="font-weight-bold f-16">
                  N/A - Trailer
                </span>
                <router-link to="/driver-trailers-search" class="text_primary f-14"
                  v-if="userRole === 'Driver' && !user.team_member.lock_trailer">View All</router-link>
              </div>
            </div>
            <div class="driver-detail-section my-5" v-if="companyDocs.length > 0">
              <div class="detail-section bg-white font-weight-bold f-16 mb-3">
                {{
                  companyDocs[0].company_name
                }}
              </div>
              <a class="detail-section bg-white mb-3" :href="docs.document" target="_blank"
                v-for="(docs, index) in companyDocs" :key="index">
                <div>
                  <p class="f-14 mb-1 font-weight-bold">
                    {{ docs.description ? docs.description : "-" }}
                  </p>
                  <p class="f-12 mb-0 text-left" :class="docs.expired_status">
                    {{
                      docs.expiry_date
                      ? "Expiring " +
                      moment(docs.expiry_date).format("MMM DD, YYYY")
                      : "-"
                    }}
                  </p>
                </div>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_4849_341)">
                    <path
                      d="M10 0C4.48582 0 0 4.48582 0 10C0 15.5142 4.48582 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48582 15.5142 0 10 0ZM13.0892 10.5892L8.9225 14.7558C8.76 14.9183 8.54668 15 8.33332 15C8.12 15 7.90664 14.9183 7.74414 14.7558C7.41832 14.43 7.41832 13.9033 7.74414 13.5775L11.3217 10L7.74418 6.4225C7.41836 6.09668 7.41836 5.57 7.74418 5.24418C8.07 4.91836 8.59668 4.91836 8.9225 5.24418L13.0892 9.41086C13.415 9.73668 13.415 10.2633 13.0892 10.5892Z"
                      fill="#2D69F6" />
                  </g>
                  <defs>
                    <clipPath id="clip0_4849_341">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal id="pinModal" centered hide-footer :title="'Pin'" :no-close-on-backdrop="true" size="sm" no-close-on-esc>
        <template #modal-header="{ close }">
          <h5 class="modal-title">Pin</h5>
          <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
            Close Modal
          </b-button>
        </template>
        <div class="d-block text-center">
          <b-row>
            <b-col cols="12">
              <b-form @submit.prevent="handleFormSubmit">
                <b-form-group label="Pin Number" class="text-left">
                  <b-form-input type="text" v-model="form.pin_code" placeholder="Enter Pin Number" required
                    pattern="\d{4}" title="Max 4 Digits Allowed"></b-form-input>
                  <p v-if="errors.pin_code" class="field-error">
                    This field is required.
                  </p>
                </b-form-group>
                <b-button type="submit" variant="primary" class="save w-100">Submit</b-button>
              </b-form>
            </b-col>
          </b-row>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as moment from "moment-timezone";
export default {
  name: "driver-details",
  data() {
    return {
      moment,
      showDriversData: false,
      errors: {
        pin_code: false,
      },
      form: {
        pin_code: "",
      },
    };
  },
  computed: {
    ...mapGetters(["allDriverDocs", "userRole", "user"]),
    driverDocs() {
      return this.allDriverDocs.data
        .filter((item) => item?.document_of?.role === "Driver")
        .map((item) => {
          if (item.expiry_date) {
            let color;
            const currentDate = new Date();
            const expiryDate = new Date(item.expiry_date);
            const diffDays = parseInt(
              (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
              10
            );
            if (diffDays > 31) {
              color = "black-text";
            } else if (diffDays <= 31 && diffDays >= 1) {
              color = "yellow-text";
            } else color = "red-text";
            return { ...item, expired_status: color };
          } else return { ...item };
        });
    },
    vehicleDocs() {
      return this.allDriverDocs.data
        .filter((item) => item?.document_of?.type === "Vehicle")
        .map((item) => {
          if (item.expiry_date) {
            let color;
            const currentDate = new Date();
            const expiryDate = new Date(item.expiry_date);
            const diffDays = parseInt(
              (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
              10
            );
            if (diffDays > 31) {
              color = "black-text";
            } else if (diffDays <= 31 && diffDays >= 1) {
              color = "yellow-text";
            } else color = "red-text";
            return { ...item, expired_status: color };
          } else return { ...item };
        });
    },
    trailerDocs() {
      return this.allDriverDocs.data
        .filter((item) => item?.document_of?.type === "Trailer")
        .map((item) => {
          if (item.expiry_date) {
            let color;
            const currentDate = new Date();
            const expiryDate = new Date(item.expiry_date);
            const diffDays = parseInt(
              (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
              10
            );
            if (diffDays > 31) {
              color = "black-text";
            } else if (diffDays <= 31 && diffDays >= 1) {
              color = "yellow-text";
            } else color = "red-text";
            return { ...item, expired_status: color };
          } else return { ...item };
        });
    },
    companyDocs() {
      return this.allDriverDocs.data
        .filter((item) => item?.document_of?.role === "Company")
        .map((item) => {
          if (item.expiry_date) {
            let color;
            const currentDate = new Date();
            const expiryDate = new Date(item.expiry_date);
            const diffDays = parseInt(
              (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
              10
            );
            if (diffDays > 31) {
              color = "black-text";
            } else if (diffDays <= 31 && diffDays >= 1) {
              color = "yellow-text";
            } else color = "red-text";
            return { ...item, expired_status: color };
          } else return { ...item };
        });
    },
  },
  methods: {
    ...mapActions(["setNotification", "getAllDriverDocs", "getUserByID", "setLoader", "logoutUser", "retrieveDocument"]),
    async openLink(id) {
      const doc = await this.retrieveDocument(id);
      window.open(doc.document, "_blank");
    },
    async getDocs(id, pin) {
      try {
        await this.getAllDriverDocs({
          driver_id: id,
          pin_code: pin,
        });
        this.showDriversData = true;
        this.$bvModal.hide("pinModal");
      } catch (e) {
        this.setNotification({
          msg: e.data,
          type: "error",
          color: "red",
        });
      }
    },
    OnlogoutUser() {
      this.logoutUser();
      this.$intercom.shutdown()
      this.$router.push("/login");
    },
    async handleFormSubmit() {
      await this.getDocs(this.$route.query?.driver, this.form.pin_code)
    },
  },
  async mounted() {
    if (this.userRole && this.userRole === 'Driver') {
      try {
        this.setLoader(true)
        await this.getUserByID()
        await this.getDocs(this.user.team_member.id, this.user.team_member.pin_code)
        this.setLoader(false)
      }
      catch (e) {
        console.log(e)
        this.setLoader(false)
      }
    }
    else {
      if (!this.allDriverDocs) {
        if (this.$route.query?.pin) {
          await this.getDocs(this.$route.query?.driver, this.$route.query?.pin)
        }
        else this.$bvModal.show("pinModal");
      } else this.showDriversData = true
    }
  },
};
</script>
<style>
.detail-section {
  padding: 13px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  box-shadow: 0 4px 50px 0 rgba(0, 0, 0, .1);
}

.red-text {
  color: #ff0000 !important;
}

.yellow-text {
  color: #ffc800 !important;
}

#dropdown-right-2 button {
  background-color: transparent !important;
  color: white !important;
  font-weight: 600;
  font-size: var(--small);
  border: unset;
}

@media (min-width: 576px) {
  .modal-sm {
    max-width: 300px !important;
  }
}

@media (max-width: 500px) {
  .detail-section>div>p {
    width: 200px;
  }
}
</style>
