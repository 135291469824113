<template>
    <div class="d-block body mb-7rem">
        <div class="mb-3 d-flex align-items-center justify-content-between px-4 mt-4rem">
            <h3 class="f-18 font-weight-bold text-left px-0 mb-0">Notes</h3>
            <div class="d-flex align-items-center justify-content-end cursor-pointer" v-if="allowUpdate" @click="addNote">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.5 12V6.5V12ZM6.5 6.5V1V6.5ZM6.5 6.5H12H6.5ZM6.5 6.5H1H6.5Z" fill="#367BF6" />
                    <path d="M6.5 12V6.5M6.5 6.5V1M6.5 6.5H12M6.5 6.5H1" stroke="#367BF6" stroke-width="1.5"
                        stroke-linecap="round" />
                </svg>
                <p class="text_primary ml-2 mb-0 fw-600">Add Note</p>
            </div>
        </div>
        <b-table responsive :fields="fields" :items="items" hover :current-page="currentPage" show-empty>
            <template #cell(note)="data">
                <span v-html="data.value" class="notes-item text-pre-line"></span>
            </template>
            <template #cell(action)="data">
                <div v-if="allowUpdate && data.item.user_id === user.team_member.id">
                    <svg @click="editNote(data.item)" class="mr-4 cursor-pointer" width="21" height="21" viewBox="0 0 21 21"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.9452 17.9664L1.71484 19.7109L3.45934 13.4806L14.8678 2.12751C14.9967 1.99564 15.1508 1.89086 15.3207 1.81932C15.4907 1.74779 15.6733 1.71094 15.8578 1.71094C16.0422 1.71094 16.2248 1.74779 16.3948 1.81932C16.5647 1.89086 16.7188 1.99564 16.8477 2.12751L19.2983 4.59196C19.428 4.72066 19.531 4.87381 19.6014 5.04251C19.6717 5.21123 19.7078 5.3922 19.7078 5.57497C19.7078 5.75774 19.6717 5.93871 19.6014 6.10743C19.531 6.27615 19.428 6.42928 19.2983 6.55798L7.9452 17.9664Z"
                            stroke="#2CCE89" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <svg @click="showNoteDeletePopup(data.item.id)" class="cursor-pointer" width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 5.15704H17.6139" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path
                            d="M3.0766 5.15704H15.537V17.6175C15.537 17.9846 15.3911 18.3368 15.1315 18.5964C14.8719 18.856 14.5197 19.0019 14.1525 19.0019H4.46109C4.0939 19.0019 3.74175 18.856 3.4821 18.5964C3.22247 18.3368 3.0766 17.9846 3.0766 17.6175V5.15704Z"
                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                            d="M5.84607 5.15347V4.46123C5.84607 3.54325 6.21073 2.66287 6.85984 2.01377C7.50894 1.36466 8.38932 1 9.3073 1C10.2253 1 11.1057 1.36466 11.7548 2.01377C12.4039 2.66287 12.7685 3.54325 12.7685 4.46123V5.15347"
                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M7.23175 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M11.385 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </div>
                <span v-else>-</span>
            </template>
        </b-table>
        <NotesSection :noteURL="noteURL" :notesData="notesData" @updateNotes="updateNotes" @clearNote="notesData = null" />
        <DeleteModal v-if="showDeletePopup" @confirm="deleteItem($event)"/>
    </div>
</template>
<script>
import NotesSection from './NotesSection.vue';
import { mapActions, mapGetters } from 'vuex';
import DeleteModal from "@/components/DeleteModal.vue";
import * as moment from "moment-timezone";
export default {
    components:{DeleteModal,NotesSection},
    name: "Notes",
    props: ["noteURL", 'allowUpdate'],
    data() {
        return {
            items: [],
            noteID:null,
            showDeletePopup:false,
            userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            currentPage: 1,
            notesData: null,
            fields: [
                {
                    label: "Date / Time",
                    key: "action_time",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "user",
                    label: "User",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    label: "Note",
                    key: "note",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
            ]
        }
    },
    computed: {
        ...mapGetters(['user'])
    },
    methods: {
        ...mapActions(['getUserNotes','deleteUserNote','setNotification']),
        showNoteDeletePopup(id) {
            this.showDeletePopup = true;
            this.noteID = id;
        },
        async deleteItem(status) {
            if (status) {
                try {
                    await this.deleteUserNote({
                        url:this.noteURL,
                        id: this.noteID +"/"
                    })
                    await this.setNotification({
                        msg: "Note successfully deleted!!",
                        type: "success",
                        color: "green",
                    });
                    this.showDeletePopup = null
                    this.showDeletePopup = false;
                    this.updateNotes()
                }
                catch (e) {
                    console.log(e)
                }
            }
            else this.showDeletePopup = false
        },
        addNote() {
            this.$bvModal.show("modal-notes-section");
        },
        async setUserNotes() {
            const data = await this.getUserNotes(this.noteURL + "/")
            this.items = []
            data.map((item) => {
                this.items.push({
                    id: item.id,
                    user: item.actor_name,
                    action_time: item.action_time ? moment.tz(item.action_time, this.userTimeZone).format("MMM DD, YYYY HH:mm") : '-',
                    note: item.note,
                    user_id: item.actor
                })
            })
        },
        editNote(data) {
            this.notesData = data
            this.$bvModal.show("modal-notes-section");
        },
        updateNotes() {
            this.$emit("updateNotes")
            this.setUserNotes()
        }
    },
    async mounted() {
        this.setUserNotes()
    }
}
</script>
<style>
.notes-item>p {
    margin-bottom: 0px !important;
}
</style>