<template>
  <b-modal id="addTagsModal" :header-bg-variant="'white'" header-class="border-none"
    footer-class="border-none justify-content-center" no-close-on-backdrop>
    <template #modal-header="{ close }">
      <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
        Close Modal
      </b-button>
    </template>
    <h4 class="text-center font-weight-bold">Add Tags</h4>
    <v-app class="add-tags">
      <v-combobox v-model="tagsData" :items="tagsData" label="Add Tags" :menu-props="{ value: false }" class="tags-select"
        multiple chips append-icon="">
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <v-chip v-bind="attrs" :input-value="selected" close @click:close="remove(item)">
            <span class="pr-2">
              {{ item }}
            </span>
          </v-chip>
        </template>
      </v-combobox>
      <div class="d-flex align-items-center flex-wrap">
        <v-chip v-for="tag in tags" :key="tag.id" class="equipment-chips ml-2 width-fit-content cursor-pointer mb-2"
          @click="addTag(tag.add_tag)" append-icon="">
          {{ tag.add_tag }}
        </v-chip>
      </div>
    </v-app>
    <template #modal-footer="">
      <b-button class="close-btn" variant="secondary" @click="cancel()"> Close </b-button>
      <b-button class="save-btn" variant="primary" @click="confirmTags()"> Add </b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AddTags",
  props: ["existingTags", "type","equipmentID","driverID","co_driver"],
  data() {
    return {
      tagsData: [],
    };
  },
  computed: {
    ...mapGetters(["tags", "company", "currentEquipment", "currentTeamMember","userCompany","userCompany"]),
  },

  methods: {
    ...mapActions(["updateEquipment", "setNotification", "getAllTags", "updateTeamMember",'addCompanyTag']),
    remove(item) {
      this.tagsData = this.tagsData.filter((chip) => item !== chip);
    },
    async confirmTags() {
      let tempTags = [];
      let existingTagsData = [];
      if (this.tags.length === 0) tempTags = this.tagsData;
      else {
        this.tagsData.map((tag) => {
          const foundTag = this.tags.filter(
            (existingTag) => existingTag.add_tag === tag
          );
          if (foundTag.length === 0) {
            tempTags.push(tag);
          } else {
            existingTagsData.push(foundTag[0].id);
          }
        });
      }
      let add_tag = null;
      tempTags.map((item, index) =>
        index === 0 ? (add_tag = item) : (add_tag += "," + item)
      );
      console.log(tempTags)
      const promises = tempTags.map(async(item)=>{
        try {
          const id = await this.addCompanyTag({add_tag: item, company: this.userCompany})
          return id
        }
        catch(e){
          await this.setNotification({
              msg: `${item} ${e}`,
              type: "error",
              color: "red",
            });
        }
      })
      await Promise.all(promises).then(async(item)=>{
        item.map((newTags)=>{
          existingTagsData.push(newTags.id)
        })
      })
      let finalTags = existingTagsData.map((item) => item);
      try {
        if (this.type === 'Equipment') {
          delete this.currentEquipment.picture;
          delete this.currentEquipment.company;
          delete this.currentEquipment.country;
          let equipmentData = {
            ...this.currentEquipment,
            state: this.currentEquipment.state.id
              ? this.currentEquipment.state.id
              : 54,
            plate_no: this.currentEquipment.plate_no
              ? this.currentEquipment.plate_no.toUpperCase()
              : null,
            vin_no: this.currentEquipment.vin_no
              ? this.currentEquipment.vin_no.toUpperCase()
              : null,
          };
          await this.updateEquipment({
            id: this.equipmentID,
            payload: { ...equipmentData, tags: finalTags },
          });
            await this.setNotification({
              msg: `Tags updated for ${this.currentEquipment.unit_no}`,
              type: "success",
              color: "green",
            });
            this.$bvModal.hide("addTagsModal");
            this.$emit("confirmTags", true);
        }
          else {
            delete this.currentTeamMember.profile_picture;
            delete this.currentTeamMember.user.password;
            let teamMemberData = {
              ...this.currentTeamMember,
              state: this.currentTeamMember.state.id
                ? this.currentTeamMember.state.id
                : 54,
              license_state: this.currentTeamMember.license_state.id
                ? this.currentTeamMember.license_state.id
                : 54,
              activate_date: this.currentTeamMember.activate_date
                ? this.currentTeamMember.activate_date
                : null,
              clearing_house_date: this.currentTeamMember.clearing_house_date
                ? this.currentTeamMember.clearing_house_date
                : null,
              dob: this.currentTeamMember.dob ? this.currentTeamMember.dob : null,
              inactive_date: this.currentTeamMember.inactive_date
                ? this.currentTeamMember.inactive_date
                : null,
              pre_emp_drug_test_date: this.currentTeamMember.pre_emp_drug_test_date
                ? this.currentTeamMember.pre_emp_drug_test_date
                : null,
              pre_emp_mvr_date: this.currentTeamMember.pre_emp_mvr_date
                ? this.currentTeamMember.pre_emp_mvr_date
                : null,
              default_vehicle:this.currentTeamMember.default_vehicle ? this.currentTeamMember.default_vehicle.id : null,
              default_trailer:this.currentTeamMember.default_trailer ? this.currentTeamMember.default_trailer.id : null,
              co_driver: this.co_driver,
              pre_emp_clearing_house_date: this.currentTeamMember
                .pre_emp_clearing_house_date
                ? this.currentTeamMember.pre_emp_clearing_house_date
                : null,
              hazmat_date: this.currentTeamMember.hazmat_date
                ? this.currentTeamMember.hazmat_date
                : null,
              insurance_date: this.currentTeamMember.insurance_date
                ? this.currentTeamMember.insurance_date
                : null,
              license_date: this.currentTeamMember.license_date
                ? this.currentTeamMember.license_date
                : null,
              medical_card_date: this.currentTeamMember.medical_card_date
                ? this.currentTeamMember.medical_card_date
                : null,
              mvr_review_date: this.currentTeamMember.mvr_review_date
                ? this.currentTeamMember.mvr_review_date
                : null,
              other_date: this.currentTeamMember.other_date
                ? this.currentTeamMember.other_date
                : null,
              twic_card_date: this.currentTeamMember.twic_card_date
                ? this.currentTeamMember.twic_card_date
                : null,
              lock_trailer: this.currentTeamMember.lock_trailer ? true : false,
            };
            await this.updateTeamMember({
              id: this.driverID,
              payload: { ...teamMemberData, tags: finalTags },
            });
            await this.setNotification({
              msg: `Tags updated for ${this.currentTeamMember.user.first_name} ${this.currentTeamMember.user.last_name}`,
              type: "success",
              color: "green",
            });
            this.$bvModal.hide("addTagsModal");
            this.$emit("confirmTags", true);
          }
      } catch (error) {
        if (typeof error === "object") {
          console.log(error);
          this.errors = error;
          // eslint-disable-next-line no-ex-assign
        }
      }
      
    },
    cancel() {
      this.$bvModal.hide("addTagsModal");
      this.$emit("confirmTags", false);
    },

    addTag(data) {
      if (this.tagsData.indexOf(data) === -1) this.tagsData.push(data);
    },
  },
  async mounted() {
    await this.getAllTags(this.userCompany);
    this.$bvModal.show("addTagsModal");
    this.tagsData = this.existingTags.map((item) => item.add_tag);
  },
};
</script>

<style>
.border-none {
  border: none !important;
}

.close-btn {
  width: 100px;
  color: var(--black) !important;
}

.save-btn {
  color: #fff;
  font-size: 14px;
  width: 95px;
  height: 36px;
  background-color: var(--btn-bg-color) !important;
}
</style>
