let socket;

export const connectSocket = () => {
  if (!socket) {
  socket = new WebSocket(`wss://${process.env.VUE_APP_WSS_URL}ws/notifications/?token=${localStorage.getItem("token")}`);

    socket.onmessage = (event) => {
      console.log("Message from server:", event.data);
      try {
        const data = JSON.parse(event.data);
        console.log("Parsed message:", data);
      } catch (e) {
        console.error("Failed to parse message:", e);
      }
    };

    socket.onclose = (event) => {
      console.log("Disconnected from WebSocket server:", event.reason);
      console.log(event)
      if (event.wasClean) {
        console.log("Connection closed cleanly.");
      } else {
        console.error("Connection closed abruptly.");
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
  }
  return socket;
};

export const disconnectSocket = () => {
  if (socket) {
    socket.close();
    socket = null;
  }
};

export const getSocket = () => {
  return socket;
};
