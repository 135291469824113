<template>
    <b-modal id="send-notification-modal" centered no-close-on-backdrop :footer-class="'d-block px-3 pt-0 pb-3 m-0'"
        :body-class="'equipment-edit-modal'" no-close-on-esc>
        <template #modal-header="{ close }">
            <h5 class="modal-title">
                Send Notification
            </h5>
            <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                Close Modal
            </b-button>
        </template>
        <div class="body">
            <b-row>
                <b-col cols="12" class="mt-3">
                    <div class="text-left">
                        <label class="state">Enter Message:</label>
                        <b-form-textarea id="message" placeholder="Enter Message" rows="5"
                            v-model="message"></b-form-textarea>
                        <p v-if="errors && errors.message" class="field-error">
                            {{ errors.message[0] }}
                        </p>
                    </div>
                </b-col>
            </b-row>
        </div>
        <template #modal-footer>
            <div class="text-right modal-footer border-0 p-0 mx-2">
                <b-button class="mx-0 mr-3 h-40px w-100px m-0" @click="resetForm()" variant="secondary">Cancel</b-button>
                <div class="d-flex align-items m-0">
                    <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary" @click="sendMessage()">Send</b-button>
                </div>
            </div>
        </template>
    </b-modal>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    name: "SendMessagetoDrivers",
    props:['ids'],
    data() {
        return {
            message: "",
            errors: {}
        }
    },
    methods: {
        ...mapActions(['sendMessageToDrivers', "setLoader"]),
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        resetForm() {
            this.message = "",
            this.errors = {}
            this.$bvModal.hide("send-notification-modal");
            this.$emit("close");
        },
        async sendMessage() {
            try {
                this.setLoader(true)
                const nullKeys = this.checkNullValues({
                    message: this.message
                })
                if (nullKeys.length === 0) {
                    await this.sendMessageToDrivers({
                        message: this.message,
                        drivers: this.ids
                    })
                    this.setLoader(false)
                    this.resetForm()
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                this.setLoader(false)
                if (typeof error === "object") {
                    this.errors = error;
                }
            }
        }
    },
}
</script>
