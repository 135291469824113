<template>
    <div>
        <b-modal id="modal-bulk-expiry-date-update" no-close-on-esc centered no-close-on-backdrop hide-footer>
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Expiry Date
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="text-left mb-3">
                <label for="modal-bulk-expiry-date" class="state">Expiry Date:</label>
                <b-form-datepicker id="modal-bulk-expiry-date" class="" v-model="expiry_date"
                    reset-button></b-form-datepicker>
                <p v-if="errors && errors.expiry_date" class="field-error">
                    {{ errors.expiry_date[0] }}
                </p>
            </div>
            <div class="text-right modal-footer border-0 p-0 mt-3">
                <b-button class="mx-0 mr-3 h-40px w-100px" @click="resetPopup()" variant="secondary">Cancel</b-button>
                <b-button class="mx-0 mb-0 h-40px w-100px mt-0" variant="primary" @click="bulkUpdateDocs">Save</b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    name: "BulkExpiryDateUpdate",
    props: ["docIds"],
    data() {
        return {
            errors: {},
            expiry_date: ""
        }
    },
    methods: {
            ...mapActions(['updateBulkDocument', "setLoader", "setNotification"]),
            resetPopup() {
                this.expiry_date = ""
                this.errors = {}
                this.$bvModal.hide('modal-bulk-expiry-date-update')
                this.$emit('close')
            },
            checkNullValues(payload) {
                let nullKeys = [];
                for (let [key, value] of Object.entries(payload)) {
                    if (!value) {
                        nullKeys.push(key);
                    }
                }
                return nullKeys;
            },
            async bulkUpdateDocs() {
                try {
                    const nullKeys = this.checkNullValues({
                        expiry_date: this.expiry_date
                    });
                    console.log(nullKeys)
                    if (nullKeys.length === 0) {
                        this.setLoader(true)
                        await this.updateBulkDocument({
                            "ids": this.docIds,
                            "expiry_date": this.expiry_date
                        })
                        this.setLoader(false)
                        await this.setNotification({
                            msg: `Documents Updated Successfully`,
                            type: "success",
                            color: "green",
                        });
                        this.setLoader(false)
                        this.$emit("updateDocs")
                        this.resetPopup()
                    }
                    else {
                        let error = {};
                        nullKeys.forEach((x) => {
                            error[x] = ["This field is required."];
                        });
                        throw error;
                    }
                }
                catch (error) {
                    console.log(error)
                    this.setLoader(false)
                    if (typeof error === "object") {
                        this.errors = error;
                    }
                }
        },
    },
}
</script>