<template>
    <b-modal id="modal-notes-section" centered hide-footer no-close-on-backdrop no-close-on-esc size="md"
        :body-class="'px-3 pt-2 overflow-preview-auto modal-custom-padding'" :footer-class="'d-block border-0 pt-0 pb-3 m-0'"
        :header-class="'align-items-center'" scrollable>
        <template #modal-header="{ close }">
            <h5 class="modal-title">
                {{ editNote ? 'Edit Note': 'Add Note' }}
            </h5>
            <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                Close Modal
            </b-button>
        </template>
        <div class="d-block text-center">
            <p class="f-14 text-left my-2">Note:</p>
            <div class="mention-input" :style="d_mentionInputStyles">
                <div :class="d_containerClass" :style="d_containerStyles">
                    <div :class="d_mainContainerClass" :style="d_mainContainerStyles">
                        <div class="mention-input__input-container" @focus="handleFocus">
                            <vue-editor ref="editor" class="mention-input__input-item-container relative"
                                :editorOptions="d_editorOptions" @focus="handleFocus" @input="handleInput"
                                @blur="handleBlur" v-model="value" :placeholder="placeholder">
                            </vue-editor>
                            <p v-if="errors && errors.value" class="field-error mt-2 text-left">
                                {{ errors.value[0] }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-right modal-footer border-0 p-0 mt-4">
            <b-button class="mx-0 h-40px w-100px m-0 mr-3" @click="resetPopup()" variant="secondary">{{ editNote ? 'Close' :
                'Cancel' }}</b-button>
            <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary" @click="setUserNotes">Save</b-button>
        </div>
    </b-modal>
</template>
<script>
import { VueEditor } from "vue2-editor";
import { mapActions } from "vuex";
import "quill-mention";
function isNode(v, title = "GLOBALS.NODE.ISNODE") {
    if (v instanceof Element) return true;
    return !!console.error(new Error(`ERR::${title}! ${v} is not a node`));
}

function componentBlurred(container) {
    if (!isNode(container, "GLOBALS.EVENT.COMPONENTBLURRED")) return false;
    if (!container.contains(document.activeElement)) return true;
    return false;
}

function checkItemClickedForBlur() {
    if (componentBlurred(this.$el)) {
        this.d_focus = false;
        this.$emit("input", { value: this.value, mentions: this.d_mentions });
        this.$emit("blur");
    }
}
export default {
    name: "AddNotes",
    components: { VueEditor },
    props: ["noteURL", 'notesData'],
    data() {
        return {
            d_focus: false,
            d_setvalue: !!this.value,
            d_mentions: [],
            value: "",
            placeholder: "Add note or tag others using @ symbol…",
            inline: true,
            slim: false,
            editNote: false,
            errors: {}
        };
    },
    watch: {
        notesData() {
            if (this.notesData) {
                this.editNote = true
                this.value = this.notesData.note
            }
        }
    },
    computed: {
        d_editorOptions() {
            // const { d_atValues } = this;
            return {
                theme: null,
                modules: {
                    toolbar: false,
                    mention: {
                        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                        mentionDenotationChars: ["@"],
                        source: async (searchTerm, renderList) => {
                            renderList([{ id: "loading", value: "Loading....", disabled: true }]);
                            try {
                                const url = `search=${searchTerm}&page=1&page_size=10`;
                                const data = await this.getAllUsersByUsername(url);
                                if (data.records?.length) {
                                    renderList(
                                        data.records.map((user) => ({
                                            id: user.team_member_id,
                                            value: user.team_member_name,
                                        }))
                                    );
                                } else {
                                    renderList([{ id: "no-results", value: "No results found", disabled: true }]);
                                }
                            } catch (error) {
                                console.error("Error fetching team members:", error);
                                renderList([]);
                            } finally {
                                this.isLoading = false
                            }
                        }
                    },
                },
            };
        },
        d_textAreaPadding() {
            const setValue = this.d_setvalue ? "1px" : null;
            return this.d_focus ? "2px" : setValue;
        },
        d_mainContainerClass() {
            const { inline, slim } = this;
            const out = [`mention-input__main-container`];
            if (inline) {
                out.push(`mention-input--inline`);
            }
            if (slim) {
                out.push(`mention-input__main-container--slim`);
            }
            return out;
        },
        d_mentionInputStyles() {
            return {
                zIndex: this.d_focus ? 1 : 0,
                paddingBottom: this.d_textAreaPadding,
            };
        },
        d_containerClass() {
            const container = [`mention-input__container`];
            if (this.d_focus) {
                const focusClass = this.slim
                    ? `mention-input__container--focused-slim`
                    : `mention-input__container--focused`;
                container.push(focusClass);
            }
            return container;
        },
        d_containerStyles() {
            return { marginBottom: this.d_focus || this.d_setvalue ? "-1px" : null };
        },
        d_mainContainerStyles() {
            return {
                paddingBottom: this.padded ? `${this.padded}px` : null,
                fontWeight: this.bolded ? "600" : null,
            };
        },
    },
    methods: {
        ...mapActions(['getAllUsersByUsername', 'createUserNote', 'updateUserNote', 'setNotification','setLoader']),
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        setFocus() {
            this.d_focus = true;
            this.$nextTick(() => {
                if (this.$refs && this.$refs.editor && this.$refs.editor.quill) {
                    this.$refs.editor.quill.focus();
                }
            });
        },
        handleFocus(e) {
            if (this.$el && !this.$el.contains(document.activeElement))
                this.$emit("focus");
            const { target } = e;
            this.d_focus = true;
            this.$nextTick(() => {
                if (
                    target !== this.$refs.textarea &&
                    this.$refs &&
                    this.$refs.editor &&
                    this.$refs.editor.quill
                ) {
                    this.$refs.editor.quill.focus();
                }
            });
        },
        handleBlur() {
            setTimeout(checkItemClickedForBlur.bind(this), 0);
        },
        handleInput(v) {
            if (!v) return;
            this.$nextTick(() => {
                this.$emit("input", { value: this.value, mentions: this.d_mentions });
            });
        },
        resetPopup() {
            this.d_focus = false
            this.d_setvalue = !!this.value
            this.value = ""
            this.editNote = false
            this.errors = {}
            this.$emit("clearNote")
            this.$bvModal.hide('modal-notes-section')
        },
        async setUserNotes() {
            try {
                this.errors = {}
                const nullKeys = this.checkNullValues({
                    value: this.value
                });
                if (nullKeys.length === 0) {
                    this.setLoader(true)
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(this.value, 'text/html')
                    const mentionElements = doc.querySelectorAll('.mention');
                    const tagged_users = Array.from(mentionElements).map(el => el.getAttribute('data-id'));
                    if (this.editNote) {
                        await this.updateUserNote({
                            url: this.noteURL,
                            id: this.notesData.id,
                            payload: {
                                note: this.value,
                                ...tagged_users.length ? { tagged_users } : {}
                            }
                        })
                    }
                    else {
                        await this.createUserNote({
                            url: this.noteURL+"/",
                            payload: {
                                note: this.value,
                                ...tagged_users.length ? { tagged_users } : {}
                            }
                        })
                    }
                    this.$emit("updateNotes")
                    await this.setNotification({
                        msg: `Note ${this.editNote ? 'Updated' : 'Added'} Succesfully`,
                        type: "success",
                        color: "green",
                    });
                    this.resetPopup()
                    this.$bvModal.hide("modal-notes-section")
                    this.setLoader(false)
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                this.setLoader(false)
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
            }
        }
    },
    mounted() {
    },
};
</script>
<style>
.mention {
    color: #2D69F6 !important;
    font-weight: 500;
}

.ql-editor {
    height: 165px !important;
    overflow-y: auto !important;
    font-size: 14px !important;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    background-color: #fafafa !important;
    color: black;

}

ul.ql-mention-list {
    margin-bottom: 0px !important;
    list-style-type: none !important;
}

.ql-mention-list-container.ql-mention-list-container-bottom {
    border: 1px solid #e3e3e3;
    background-color: #fafafa;
    padding: 10px 20px;
    text-align: left;
    max-height: 100px;
    overflow-y: auto;
    border-radius: 5px;
}

.ql-mention-list-item:not(.disabled) {
    cursor: pointer;
    margin-bottom: 3px;
}
</style>