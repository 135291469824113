<template>
    <div>
        <b-sidebar id="sidebar-backdrop" sidebar-class="columns-sidebar" :backdrop-variant="'dark'" backdrop shadow
            :visible="showColumns" :right="true" no-header-close no-close-on-backdrop no-close-on-esc>
            <template #header="{ }">
                <div class="d-flex align-items-center w-100 justify-content-between">
                    <div class="d-flex align-items-center w-100 mr-2">
                        <div class="text-nowrap">
                            Columns
                        </div>
                    </div>
                    <b-dropdown id="dropdown-divider" text="text" class="load-dropdown mt-0">
                        <template slot="button-content">
                            <b-icon class="dots" icon="three-dots"></b-icon>
                        </template>
                            <b-dropdown-item-button
                            @click="$emit('resetTableFields')">
                                <p class="text_secondary cursor-pointer mb-0 f-14">Reset</p>
                            </b-dropdown-item-button>
                    </b-dropdown>
                </div>
            </template>
            <div class="mt-3">
                <draggable v-model="columnFields" :element="'div'" ghost-class="ghost" @change="getUpdatedFieldsList()">
                    <div class="px-4" :class="index + 1 === columnFields.length ? 'mb-7rem':'mb-3'" v-for="(field, index) in columnFields" :key="index">
                        <div
                            :class="`align-items-center justify-content-between cursor-pointer ${field.key === '#' ? 'd-none' : 'd-flex'}`">
                            <b-form-checkbox class="pl-0" :id="'checkbox-' + index" v-model="field.visible" :disabled="field.disabled"
                                @change="updateFields()" :name="'checkbox-' + index" @click.native.capture.stop>
                                {{ field.label }}
                            </b-form-checkbox>
                            <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="2" cy="16" r="2" transform="rotate(-90 2 16)" fill="#C4C4C4" />
                                <circle cx="2" cy="9" r="2" transform="rotate(-90 2 9)" fill="#C4C4C4" />
                                <circle cx="2" cy="2" r="2" transform="rotate(-90 2 2)" fill="#C4C4C4" />
                            </svg>
                        </div>
                        <hr />
                    </div>
                </draggable>
            </div>
            <div class="sidebar-footer position-fixed">
                <div class="text-right modal-footer border-0 p-0">
                    <b-button class="mx-0 h-40px w-100px" variant="secondary" @click="columnFields = []; $emit('resetData');">Close</b-button>
                </div>
            </div>
        </b-sidebar>
    </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
    data() {
        return {
            columnFields: []
        }
    },
    name: "TableColumns",
    props: ['showColumns', 'fields'],
    components: { draggable },
    watch: {
        showColumns() {
            if (this.showColumns) {
                this.columnFields = this.fields
            }
        }
    },
    methods: {
        updateFields() {
            this.$emit("updateFields", this.columnFields)
        },
        getUpdatedFieldsList() {
            this.$emit("getUpdatedFieldsList", this.columnFields)
        }
    }
}
</script>