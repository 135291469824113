<template>
  <b-container fluid class="mt-2 px-1 px-sm-3 pb-3 team-page">
    <b-row>
      <b-col cols="" class="">
        <b-row>
          <b-col cols="">
            <div class="label mb-3 text-left">
              <router-link to="/">dashboard</router-link>
              /
              <span>locations</span>
            </div>
            <div
              class="expiry-data d-flex justify-content-between align-items-center flex-wrap mb-3"
            >
              <h2>Locations</h2>
              <div
                class="d-flex justify-content-between justify-content-sm-center flex-wrap"
              >
                <b-button
                  class="export d-flex justify-content-center align-items-center mx-1 my-1"
                  @click="downloadExcel"
                >
                  <b-icon icon="cloud-upload" aria-hidden="true"></b-icon>
                  <p class="mb-0 ml-3 text-white">Export to CSV</p>
                </b-button>
                <b-button
                  id="modalTrigger"
                  @click="openModal"
                  class="export equipment d-flex justify-content-center align-items-center mx-1 my-1"
                >
                  <b-icon icon="plus" aria-hidden="true"></b-icon>
                  <p class="mb-0 ml-2 text-white">Add Location</p>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="data-table team-data-table bg-white px-4 rounded pt-4">
              <div class="table_row team_table_row">
                <div class="text-left">
                  <h4>Filter Data</h4>
                </div>

                <form class="equipment-border d-flex mt-4">
                  <b-form-group label="Name" class="text-left label">
                    <b-form-input
                      type="text"
                      class="mb-4 w-250 input"
                      required
                      v-model="searchName"
                    ></b-form-input>
                  </b-form-group>
                </form>

                <b-table
                  responsive
                  :fields="fields"
                  :items="searchItems"
                  striped
                  hover
                  :per-page="perPage"
                  :current-page="currentPage"
                >
                  <template #cell(Action)="data">
                    <b-icon
                      style="fill: limegreen"
                      class="mr-4"
                      icon="pencil"
                      @click="changeLocation(data.item)"
                    />
                    <b-icon
                      style="fill:#FF0000"
                      icon="trash-fill"
                      @click="showCustomerContactDeletePopup(data.item.id)"
                    />
                  </template>
                </b-table>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="entries-pagination mt-0">
          <p class="text-left" style="opacity: 0.6">
            *click on name to open item page, and edit update information there
          </p>
          <div
            class="d-flex align-items-center justify-content-between flex-wrap"
          >
            <div class="text-left show-entries ml-md-1 pl-3 pl-md-0">
              <b-form-select v-model="perPage" :options="pageOptions" />
            </div>
            <div class="pagination">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
          <p class="mt-2">{{ positionText }}</p>
        </div>
      </b-col>
    </b-row>

    <!-- Modal -->
    <b-modal
      id="addLocationModal"
      centered
      hide-footer
      :title="editDocumentFlag ? 'Edit Location' : 'Add Location'"
    >
      <div class="d-block text-center">
        <b-row>
          <b-col sm="12">
            <b-form-group
              label="Google Search"
              class="text-left input_search form-group google_search_section"
            >
              <b-input-group class="w-auto border-0">
                <vue-typeahead-bootstrap
                  class="width-90 search-google-field"
                  type="text"
                  @input="fetchPlaces($event)"
                  @hit="selectedPlace"
                  v-model="newLocation.google_search"
                  :data="allPlaces"
                />
                <b-input-group-append>
                  <b-button class="search_btn" disabled>
                    <b-icon icon="search" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <p v-if="errors.google_search" class="field-error mt-2">
                This field is required.
              </p>
            </b-form-group>
          </b-col>

          <b-col lg="6" md="12" sm="12">
            <b-form-group label="Name" class="text-left font-weight-normal">
              <b-form-input
                type="text"
                placeholder="Enter Name"
                class="unit"
                required
                v-model="newLocation.name"
              ></b-form-input>
              <p v-if="errors.name" class="field-error">
                This field is required.
              </p>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12" sm="12">
            <b-form-group label="Address" class="text-left font-weight-normal">
              <b-form-input
                readonly
                type="text"
                placeholder="Enter Address"
                class="unit"
                required
                v-model="newLocation.address"
              ></b-form-input>
              <p v-if="errors.google_search" class="field-error">
                This field is required.
              </p>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12" sm="12">
            <b-form-group label="City" class="text-left font-weight-normal">
              <b-form-input
                readonly
                type="text"
                placeholder="Enter City"
                class="unit"
                required
                v-model="newLocation.city"
              ></b-form-input>
              <p v-if="errors.google_search" class="field-error">
                This field is required.
              </p>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12" sm="12">
            <b-form-group label="State" class="text-left font-weight-normal">
              <b-form-input
                readonly
                type="text"
                placeholder="Enter State"
                class="unit"
                required
                v-model="newLocation.state"
              ></b-form-input>
              <p v-if="errors.google_search" class="field-error">
                This field is required.
              </p>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12" sm="12">
            <b-form-group
              readonly
              label="Postal Code"
              class="text-left label font-weight-normal"
            >
              <b-form-input
                readonly
                type="text"
                placeholder="Enter Postal Code"
                class="unit"
                required
                v-model="newLocation.postal_code"
              ></b-form-input>
              <p v-if="errors.google_search" class="field-error">
                This field is required.
              </p>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12" sm="12">
            <b-form-group label="Country" class="text-left font-weight-normal">
              <b-form-input
                readonly
                type="text"
                placeholder="Enter Country"
                class="unit"
                required
                v-model="newLocation.country"
              ></b-form-input>
              <p v-if="errors.google_search" class="field-error">
                This field is required.
              </p>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12" sm="12">
            <b-form-group label="Latitude" class="text-left font-weight-normal">
              <b-form-input
                readonly
                type="number"
                placeholder="Enter Latitude"
                class="unit"
                required
                v-model="newLocation.latitude"
              ></b-form-input>
              <p v-if="errors.google_search" class="field-error">
                This field is required.
              </p>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12" sm="12">
            <b-form-group
              label="Longitude"
              class="text-left font-weight-normal"
            >
              <b-form-input
                readonly
                type="number"
                placeholder="Enter Longitude"
                class="unit"
                required
                v-model="newLocation.longitude"
              ></b-form-input>
              <p v-if="errors.google_search" class="field-error">
                This field is required.
              </p>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12" sm="12">
            <b-form-group label="Phone" class="text-left font-weight-normal">
              <vue-tel-input
                v-bind="bindProps"
                default-country="US"
                class="w-100 f-14"
                v-model="newLocation.phone"
                @validate="checkNum"
              ></vue-tel-input>
              <p v-if="errors && errors.phone" class="field-error">
                This format is Incorrect.
              </p>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div class="text-right modal-footer border-0 p-0">
        <b-button
          class="mt-3 mr-3 btn cancel"
          block
          @click="$bvModal.hide('addLocationModal')"
        >
          Cancel
        </b-button>
        <b-button class="mt-3 btn save" @click="addLocation">Save</b-button>
      </div>
    </b-modal>
    <DeleteModal v-if="showLocationModal" @confirm="removeLocation($event)" />
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DeleteModal from "@/components/DeleteModal.vue";
import parse from "html-dom-parser";

export default {
  name: "Locations",
  components: {
    DeleteModal,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      items: [],
      showLocationModal: false,
      locationID: null,
      isPhoneValid: false,

      bindProps: {
        mode: "international",
        autoFormat: true,
        preferredCountries: ["US", "CA", "MX"],
        inputOptions: {
          placeholder: "Enter a phone no.",
          maxlength: 30,
        },
        dropdownOptions: {
          showSearchBox: true,
          showDialCodeInList: true,
          showFlags: true,
          showDialCodeInSelection: true,
          width: "300px",
        },
      },
      searchName: null,
      countries: [],
      states: [],
      fields: [
        {
          label: "Name",
          key: "name",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          label: "Address",
          key: "address",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          label: "City",
          key: "city",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          label: "State",
          key: "state",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          label: "Postal Code",
          key: "postal_code",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          label: "Country",
          key: "country",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          key: "Action",
          sortable: true,
          tdClass: "align-middle",
        },
      ],
      selected: null,
      pageOptions: [
        {
          value: 10,
          text: "10",
        },
        {
          value: 25,
          text: "25",
        },
        {
          value: 50,
          text: "50",
        },
        {
          value: 100,
          text: "100",
        },
      ],

      allPlaces: [],
      editDocumentFlag: false,
      newLocation: {
        google_search: "",
        name: "",
        address: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
        latitude: "",
        longitude: "",
        phone: "",
      },
      allPrediction: [],
      errors: {},
    };
  },

  computed: {
    ...mapGetters(["allLocations", "allCountries"]),
    rows() {
      return this.searchItems.length;
    },
    positionText() {
      let endIndex = this.currentPage * this.perPage,
        startIndex =
          this.rows > 0 ? (this.currentPage - 1) * this.perPage + 1 : 0;

      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.rows ? this.rows : endIndex) +
        " of " +
        this.rows +
        " entries"
      );
    },
    searchItems() {
      const searchKeysWithFields = {
        name: this.searchName,
      };
      let filteredItems = this.items;
      for (let [field, searchQuery] of Object.entries(searchKeysWithFields)) {
        console.log("------", field, searchQuery);
        if (searchQuery !== null && searchQuery !== "") {
          filteredItems = this.searchByKeys(searchQuery, field, filteredItems);
        }
      }
      return filteredItems;
    },
  },

  watch: {
    allLocations() {
      this.items = [];
      this.allLocations.map((location) => {
        this.items.push({
          google_search: location.google_search,
          name: location.name,
          address: location.address,
          city: location.city,
          state: location.state,
          postal_code: location.postal_code,
          phone: location.phone,
          country: location.country,
          longitude: location.longitude,
          latitude: location.latitude,
        });
      });
    },
  },
  methods: {
    ...mapActions([
      "setNotification",
      "postExcelData",
      "getAllLocations",
      "createLocation",
      "getAllCountries",
      "editLocation",
      "deleteLocation",
    ]),

    checkErrors() {
      if (!this.newLocation.google_search) {
        this.errors = {
          ...this.errors,
          google_search: !this.newLocation.google_search,
          phone: this.newLocation.phone ? !this.isPhoneValid : false,
          name: !this.newLocation.name,
        };

        return true;
      } else {
        this.errors.google_search = false;

        if (
          (this.newLocation.phone && !this.isPhoneValid) ||
          !this.newLocation.name
        ) {
          this.errors = {
            ...this.errors,
            phone: !this.isPhoneValid,
            name: !this.newLocation.name,
          };
          return true;
        }
        return false;
      }
    },

    selectedPlace(placeDes) {
      let currentPlaceID = this.allPredictions.filter(
        (prediction) => prediction.description === placeDes
      )[0].place_id;
      var request = {
        placeId: currentPlaceID,
        fields: [
          "name",
          "geometry",
          "formatted_address",
          "adr_address",
          "international_phone_number",
          "formatted_phone_number",
        ],
      };

      let service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
      service.getDetails(request, (place) => {
        this.errors = {};
        let name = place.name;
        let latitude = place.geometry.location.lat();
        let longitude = place.geometry.location.lng();
        let parsedAddress = parse(place.adr_address).filter(
          (place) => place.type === "tag"
        );
        console.log(place.adr_address);
        let obj = {};
        parsedAddress.forEach((add) => {
          obj[add.attribs.class] = add.children[0].data;
        });
        console.log(obj);
        let address = obj["street-address"]
          ? obj["street-address"]
          : obj.locality;
        let state = obj.region.split(", ");
        let phone = place?.international_phone_number;
        let google_search = `${
          obj["street-address"] ? obj["street-address"] + ", " : ""
        }${obj.locality ? obj.locality + ", " : ""}${obj?.region}, ${
          obj["postal-code"] ? obj["postal-code"] + ", " : ""
        }${obj["country-name"]}`;
        this.newLocation = {
          ...this.newLocation,
          google_search,
          name,
          address,
          latitude,
          longitude,
          postal_code: obj["postal-code"],
          country: obj["country-name"],
          state: state[state.length - 1],
          city: obj.locality,
          phone,
        };
      });
    },

    showCustomerContactDeletePopup(id) {
      this.showLocationModal = true;
      this.locationID = id;
    },

    async fetchPlaces(query) {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions(
        {
          input: query,
        },
        (predictions) => {
          this.allPredictions = predictions;
          this.allPlaces = predictions.map(
            (prediction) => prediction.description
          );

          // console.log(this.allPlaces)
        }
      );
    },
    checkNum(phoneObject) {
      if (phoneObject.valid !== undefined) {
        this.isPhoneValid = phoneObject.valid;
      }
    },
    searchByKeys(searchQuery, field, filteredItems) {
      console.log("----------", searchQuery, field, filteredItems);
      if (["Status", "role", "appUser"].includes(field)) {
        return filteredItems.filter(
          (x) => x[field] && x[field] === searchQuery
        );
      }
      return filteredItems.filter(
        (x) =>
          x[field] &&
          x[field]
            .toString()
            .toLowerCase()
            .includes(searchQuery.toString().toLowerCase())
      );
    },

    openModal() {
      this.errors = {};
      this.editDocumentFlag = false;
      this.newLocation = {
        google_search: "",
        name: "",
        address: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
        latitude: "",
        longitude: "",
        phone: "",
      };
      this.$bvModal.show("addLocationModal");
    },
    async downloadExcel() {
      let excelData = JSON.parse(JSON.stringify(this.searchItems));
      excelData.forEach((item) => {
        delete item.phone;
        delete item.google_search;
        delete item.longitude;
        delete item.latitude;
      });

      const link = await this.postExcelData(excelData);
      const fileLink = URL.createObjectURL(new Blob([link]));
      this.downloadURI(fileLink);
    },
    downloadURI(uri) {
      let link = document.createElement("a");
      link.setAttribute("href", uri);
      link.setAttribute("download", "export.csv");
      link.click();
    },

    async addLocation() {
      let errors = this.checkErrors();
      console.log(errors);
      if (errors) {
        return;
      } else {
        if (!this.editDocumentFlag) {
          try {
            await this.createLocation(this.newLocation);
            await this.setNotification({
              msg: "Location Successfully Added!!",
              type: "success",
              color: "green",
            });
          } catch (e) {
            await this.setNotification({
              msg: e,
              type: "error",
              color: "red",
            });
          }
        } else {
          try {
            await this.editLocation(this.newLocation);
            this.editDocumentFlag = false;
            await this.setNotification({
              msg: "Location Updated Successfully!!",
              type: "success",
              color: "green",
            });
          } catch (e) {
            await this.setNotification({
              msg: e,
              type: "error",
              color: "red",
            });
          }
        }
        console.log(this.allLocations);
        this.$bvModal.hide("addLocationModal");
      }

      this.errors = {};
    },

    changeLocation(item) {
      this.editDocumentFlag = true;
      let data = {
        google_search: item.google_search,
        name: item.name,
        address: item.address,
        city: item.city,
        state: item.state,
        postal_code: item.postal_code,
        country: item.country,
        latitude: item.latitude,
        longitude: item.longitude,
        phone: item.phone,
        id: item.id,
      };
      this.newLocation = data;
      this.errors = {};
      this.$bvModal.show("addLocationModal");
    },

    async removeLocation(item) {
      this.showLocationModal = false;
      if (item) {
        try {
          await this.deleteLocation({
            id: this.locationID,
          });
          await this.setNotification({
            msg: `Successfully deleted Location`,
            type: "success",
            color: "green",
          });
        } catch (error) {
          await this.setNotification({
            msg: error,
            type: "error",
            color: "red",
          });
        }
      }
    },
  },
  async mounted() {
    try {
      await Promise.all([this.getAllLocations()]);
    } catch (error) {
      if (error.status === 403) {
        await this.setNotification({
          msg: "You are not allowed to see Locations.",
          type: "error",
          color: "red",
        });
      }
    }
  },
};
</script>

<style>
.width-90 {
  width: 99%;
}
.search-google-field .input-group {
  border: 0;
}
.google_search_section {
  position: relative;
}
.google_search_section .input-group-append {
  position: absolute;
  right: 0;
  z-index: 5;
}

.width-90 > .input-group {
  width: 100% !important;
}

.input_search .input-group .form-control {
  height: 40px !important;
  padding-left: 12px;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.search_btn {
  background: #2D69F6 !important;
  border-radius: 5px !important;
  padding: 0px 12px !important;
  height: 40px;
}

.search_btn svg {
  fill: #fff;
}

.input_search .input-group {
  width: auto !important;
}

.input_search .b-icon svg {
  fill: #fff;
}

.search_btn:disabled {
  opacity: 1 !important;
}

.search-google-field > div > input {
  text-align: left;
}
</style>
