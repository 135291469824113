<template>
    <div class="profile-form bg-white p-3 rounded">
        <h5 class="font-weight-bold font-20 text-left mb-3">Settings</h5>
        <h6 class="font-weight-bold text-left">Email Notifications</h6>
        <b-row>
            <b-col md="12" class="mb-3">
                <div class="text-left">
                    <label>Expiry Data</label>
                    <b-form-checkbox name="check-button" class="ml-0-6rem" switch size="lg"
                        v-model="user.team_member.email_expiry_date" />
                </div>
            </b-col>
            <b-col md="12" class="mb-3">
                <div class="text-left">
                    <label>Required Docs</label>
                    <b-form-checkbox name="check-button" class="ml-0-6rem" switch size="lg"
                        v-model="user.team_member.email_missing_required_docs" />
                </div>
            </b-col>
            <b-col md="12" class="mb-3">
                <div class="text-left">
                    <label>Document Added</label>
                    <b-form-checkbox name="check-button" class="ml-0-6rem" switch size="lg"
                        v-model="user.team_member.get_document_added_email" />
                </div>
            </b-col>
            <b-col md="12" class="mb-3">
                <div class="text-left">
                    <label>DOT Mode</label>
                    <b-form-checkbox name="check-button" class="ml-0-6rem" switch size="lg"
                        v-model="user.team_member.get_driver_dot_mode_email" />
                </div>
            </b-col>
            <b-col md="12" class="mb-3">
                <div class="text-left">
                    <label>Tagged in Notes</label>
                    <b-form-checkbox name="check-button" class="ml-0-6rem" switch size="lg"
                        v-model="user.team_member.get_tagged_in_notes_email" />
                </div>
            </b-col>
        </b-row>
        <hr />
        <h6 class="font-weight-bold text-left">Push Notifications</h6>
        <b-row>
            <b-col md="12" class="mb-3">
                <div class="text-left">
                    <label>Document Added</label>
                    <b-form-checkbox name="check-button" class="ml-0-6rem" switch size="lg"
                        v-model="user.team_member.get_document_added_notification" />
                </div>
            </b-col>
            <b-col md="12" class="mb-3">
                <div class="text-left">
                    <label>DOT Mode</label>
                    <b-form-checkbox name="check-button" class="ml-0-6rem" switch size="lg"
                        v-model="user.team_member.get_driver_dot_mode_notification" />
                </div>
            </b-col>
        </b-row>
        <div class="d-flex align-items-center flex-wrap">
            <b-button to="/" class="mx-0 h-40px w-100px mt-3 mr-3 m-0" variant="secondary">Close</b-button>
            <b-button v-if="hasChanged" @click="updateUserDetails()" class="mt-3 save h-40px w-100px m-0" variant="primary"
                type="button">Save</b-button>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { checkValueExists } from '../../services/helper';
export default {
    name: "Settings",
    data() {
        return {
            defaultData: {},
            checkValueExists
        }
    },
    computed: {
        ...mapGetters(["user"]),
        hasChanged() {
            return Object.keys(this.defaultData).filter(field => this.checkValueExists(this.user.team_member[field]) != this.checkValueExists(this.defaultData[field])).length > 0
        }
    },
    methods: {
        ...mapActions(['setNotification', 'updateTeamMember', 'getUserByID']),
        async updateUserDetails() {
            try {
                await this.updateTeamMember({
                    id: this.user.team_member.id,
                    payload: {
                        email_expiry_date: this.user.team_member.email_expiry_date,
                        get_document_added_email: this.user.team_member.get_document_added_email,
                        get_driver_dot_mode_email: this.user.team_member.get_driver_dot_mode_email,
                        get_document_added_notification: this.user.team_member.get_document_added_notification,
                        get_driver_dot_mode_notification: this.user.team_member.get_driver_dot_mode_notification,
                        get_tagged_in_notes_email: this.user.team_member.get_tagged_in_notes_email,
                        email_missing_required_docs:this.user.team_member.email_missing_required_docs
                    },
                });
                await this.setNotification({
                    msg: "User Details Updated!!",
                    type: "success",
                    color: "green",
                });
                this.setDefaultData()
                await this.getUserByID()
                this.errors = {
                    phone: null,
                };
            } catch (error) {
                if (typeof error === "object") {
                    this.errors = error;
                }
            }
        },
        setDefaultData(){
            this.defaultData = {
            email_expiry_date: this.user.team_member.email_expiry_date,
            get_document_added_email: this.user.team_member.get_document_added_email,
            get_driver_dot_mode_email: this.user.team_member.get_driver_dot_mode_email,
            get_document_added_notification: this.user.team_member.get_document_added_notification,
            get_driver_dot_mode_notification: this.user.team_member.get_driver_dot_mode_notification,
            get_tagged_in_notes_email: this.user.team_member.get_tagged_in_notes_email,
            email_missing_required_docs: this.user.email_missing_required_docs
        }
        }
    },
    mounted() {
       this.setDefaultData()
    }
}
</script>