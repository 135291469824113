<template>
    <div>
        <b-sidebar id="sidebar-backdrop" :backdrop-variant="'dark'" backdrop shadow :visible="!!claimID" :right="true"
            no-header-close no-close-on-backdrop no-close-on-esc>
            <template #header="{ }">
                <div class="d-flex align-items-center w-100 justify-content-between">
                    <div class="d-flex align-items-center w-100 mr-2">
                        <div class="text-nowrap" v-if="currentClaimData">
                            {{ currentClaimData.record_no }}
                        </div>
                        <!-- <b-button class="ml-5 pe-none d-flex justify-content-center align-items-center w-100px h-40px"
                            :class="claimPayload.status === 'New'
                                ? 'new-record'
                                : claimPayload.status === 'Urgent'
                                    ? 'urgent-record text-white'
                                    : claimPayload.status === 'Open' ? 'open-record' : 'inactive-equipment text-black'"
                            :variant="claimPayload.status === 'New' ? 'warning' : claimPayload.status === 'Urgent' ? 'danger' : claimPayload.status === 'Open' ? 'success' : 'light'">
                            <p class="mb-0">{{ claimPayload.status }}</p>
                        </b-button> -->
                    </div>
                    <b-dropdown id="dropdown-divider" text="text" class="load-dropdown mt-0">
                        <template slot="button-content">
                            <b-icon class="dots" icon="three-dots"></b-icon>
                        </template>
                        <b-dropdown-item-button @click="showDeleteClaimModal = true">
                            <p class="text_secondary cursor-pointer mb-0 f-14">Delete</p>
                        </b-dropdown-item-button>
                    </b-dropdown>
                </div>
            </template>
            <div class="body" v-if="currentClaimData">
                <div class="sidebar-tabs">
                    <div class="bg-white rounded">
                        <v-tabs class="team-tabs mb-0" v-model="activeTab">
                            <v-tab key='info'>Info</v-tab>
                            <v-tab key='contacts'>Contacts</v-tab>
                            <v-tab key='documents'>Documents</v-tab>
                            <v-tab key='notes'>Notes</v-tab>
                            <v-tab key="activity" v-if="showActivitySection">Activity</v-tab>
                        </v-tabs>
                    </div>
                </div>
                <v-tabs-items v-model="activeTab">
                    <v-tab-item key='info'>
                        <hr class="mt-0" />
                        <div class="d-block text-center mt-4rem">
                            <b-row class="align-items-center px-4">
                                <h3 class="f-18 font-weight-bold col-12 text-left">Basic</h3>
                                <b-col md="6" sm="12" class="mt-3">
                                    <b-form-group label="Record # :" class="text-left mb-0">
                                        <b-form-input v-model="claimPayload.record_no" disabled type="text"
                                            placeholder="Enter Record" class="unit" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <div class="text-left select_arrow">
                                        <label class="state">Status:</label>
                                        <b-form-select v-model="claimPayload.status" :disabled="!allowUpdateRecord"
                                            class="f-12" :options="[{
                                                value: 'New',
                                                text: 'New'
                                            },
                                            {
                                                value: 'Open',
                                                text: 'Open'
                                            },
                                            {
                                                value: 'Closed',
                                                text: 'Closed'
                                            },
                                            {
                                                value: 'Urgent',
                                                text: 'Urgent'
                                            }
                                            ]" />
                                    </div>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <label for="claim-add-datepicker" class="state">Date</label>
                                    <date-picker v-model="claimPayload.date" :formatter="momentFormat"
                                        :range-separator="' - '" @clear="claimPayload.date = ''" placeholder="Select Date"
                                        class="date-picker-custom" :teleport="'body'"
                                        :disabled="!allowUpdateRecord"></date-picker>
                                    <p v-if="errors && errors.date" class="field-error">
                                        {{ errors.date[0] }}
                                    </p>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <label class="state">Time:</label>
                                    <b-form-input type="time" class="f-12" :disabled="!allowUpdateRecord"
                                        v-model="claimPayload.time"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <div class="text-left select_arrow">
                                        <label class="state">Driver:</label>
                                        <v-select class="bg_white w-100 f-12" :disabled="!allowUpdateRecord"
                                            v-model="claimPayload.driver" :options="driverOptions" item-value="id"
                                            item-text="label" :reduce="(option) => option.id" @search="searchDriver">
                                            <span slot="no-options">
                                                Search Driver ...
                                            </span>
                                        </v-select>
                                        <p v-if="errors && errors.driver" class="field-error">
                                            {{ errors.driver[0] }}
                                        </p>
                                    </div>
                                </b-col>
                                <b-col md="3" sm="12" class="mt-3 text-left">
                                    <div class="text-left select_arrow">
                                        <label class="state">Vehicle:</label>
                                        <v-select class="bg_white w-100 f-12" :disabled="!allowUpdateRecord"
                                            v-model="claimPayload.vehicle" :options="vehicleOptions" item-value="id"
                                            item-text="label" :reduce="(option) => option.id" @search="searchVehicle">
                                            <span slot="no-options">
                                                Search Vehicle ...
                                            </span>
                                        </v-select>
                                    </div>
                                </b-col>
                                <b-col md="3" sm="12" class="mt-3 text-left">
                                    <div class="text-left select_arrow">
                                        <label class="state">Trailer:</label>
                                        <v-select class="bg_white w-100 f-12" :disabled="!allowUpdateRecord"
                                            v-model="claimPayload.trailer" :options="trailerOptions" item-value="id"
                                            item-text="label" :reduce="(option) => option.id" @search="searchTrailer">
                                            <span slot="no-options">
                                                Search Trailer ...
                                            </span>
                                        </v-select>
                                    </div>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <div class="text-left select_arrow">
                                        <label class="state">Type:</label>
                                        <b-form-select v-model="claimPayload.type" :disabled="!allowUpdateRecord"
                                            class="f-12" :options="[{
                                                value: '',
                                                text: ''
                                            },
                                            {
                                                value: 'Self',
                                                text: 'Self'
                                            },
                                            {
                                                value: 'Insurance',
                                                text: 'Insurance'
                                            },
                                            {
                                                value: 'Other',
                                                text: 'Other'
                                            },
                                            {
                                                value: 'AL',
                                                text: 'AL'
                                            },
                                            {
                                                value: 'Cargo',
                                                text: 'Cargo'
                                            },
                                            {
                                                value: 'GL',
                                                text: 'GL'
                                            },
                                            {
                                                value: 'PD',
                                                text: 'PD'
                                            },
                                            {
                                                value: 'OO PD',
                                                text: 'OO PD'
                                            },
                                            {
                                                value: 'OO NTL',
                                                text: 'OO NTL'
                                            },
                                            {
                                                value: 'OccAcc',
                                                text: 'OccAcc'
                                            },
                                            {
                                                value: 'W.C.',
                                                text: 'W.C.'
                                            }
                                            ]" />
                                    </div>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <div class="text-left select_arrow">
                                        <b-form-group label="Claim #:" class="text-left mb-0">
                                            <b-form-input type="text" class="f-12" placeholder="Enter Claim #"
                                                v-model="claimPayload.claim_number"
                                                :disabled="!allowUpdateRecord"></b-form-input>
                                        </b-form-group>
                                    </div>
                                </b-col>
                                <b-col md="12" class="text-left mt-3">
                                    <label class="state text-left">Notes:</label>
                                    <b-form-textarea id="textarea" class="f-12" :disabled="!allowUpdateRecord"
                                        placeholder="Enter Notes" rows="5" v-model="claimPayload.notes"></b-form-textarea>
                                </b-col>
                            </b-row>
                            <hr class="m-4" />
                            <b-row class="px-4">
                                <h3 class="f-18 font-weight-bold col-12 text-left">Accidents</h3>
                                <b-col md="12" sm="12" class="text-left mt-3">
                                    <label class="state">Accidents:</label>
                                    <v-select class="bg_white w-100 f-12" :disabled="!allowUpdateRecord"
                                        v-model="claimPayload.accident_number" :options="accidentOptions" item-value="id"
                                        item-text="label" :reduce="(option) => option.id" @search="searchAccidents">
                                        <span slot="no-options">
                                            Search Accident # ...
                                        </span>
                                    </v-select>
                                </b-col>
                            </b-row>
                            <hr class="m-4" />
                            <b-row class="px-4 mb-7rem">
                                <h3 class="f-18 font-weight-bold col-12 text-left">Details</h3>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <label class="state">Address:</label>
                                    <b-form-input v-model="claimPayload.address" :disabled="!allowUpdateRecord" class="f-12"
                                        placeholder="Enter Address"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <label class="state">City:</label>
                                    <b-form-input v-model="claimPayload.city" class="f-12"
                                        placeholder="Enter City"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <div class="text-left select_arrow">
                                        <label class="state">State:</label>
                                        <v-select class="bg_white w-100 f-12" :disabled="!allowUpdateRecord"
                                            v-model="claimPayload.state" :options="states" item-value="id" item-text="label"
                                            :reduce="(option) => option.id">
                                        </v-select>
                                    </div>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <label class="state">Postal Code:</label>
                                    <b-form-input v-model="claimPayload.postal_code" :disabled="!allowUpdateRecord"
                                        class="f-12" placeholder="Enter Postal Code"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <label class="state">Customer:</label>
                                    <b-form-input v-model="claimPayload.customer" :disabled="!allowUpdateRecord"
                                        class="f-12" placeholder="Enter Customer"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3 text-left">
                                    <label class="state">Load #:</label>
                                    <b-form-input v-model="claimPayload.load_number" :disabled="!allowUpdateRecord"
                                        class="f-12" placeholder="Enter Load #"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label class="state">Insurance:</label>
                                    <b-form-input v-model="claimPayload.insurance_name" :disabled="!allowUpdateRecord"
                                        class="f-12" placeholder="Enter Insurance Name"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label class="state">Policy #’s:</label>
                                    <b-form-input v-model="claimPayload.policy_type" :disabled="!allowUpdateRecord"
                                        class="f-12" placeholder="Enter Policy #’s"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label class="state">Damage Value:</label>
                                    <b-form-input v-model="claimPayload.damage_value" :disabled="!allowUpdateRecord"
                                        class="f-12" placeholder="Enter Damage Value"></b-form-input>
                                </b-col>
                            </b-row>
                        </div>
                    </v-tab-item>
                    <v-tab-item key='contacts'>
                        <div class="d-block body">
                            <div class="mb-3 d-flex align-items-center justify-content-between px-4 mt-4rem">
                                <h3 class="f-18 font-weight-bold text-left px-0 mb-0">Contacts</h3>
                                <div class="d-flex align-items-center justify-content-end cursor-pointer"
                                    @click='$bvModal.show("modal-contact")' v-if="allowUpdateRecord">
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.5 12V6.5V12ZM6.5 6.5V1V6.5ZM6.5 6.5H12H6.5ZM6.5 6.5H1H6.5Z"
                                            fill="#367BF6" />
                                        <path d="M6.5 12V6.5M6.5 6.5V1M6.5 6.5H12M6.5 6.5H1" stroke="#367BF6"
                                            stroke-width="1.5" stroke-linecap="round" />
                                    </svg>
                                    <p class="text_primary ml-2 mb-0 fw-600">Add Contact</p>
                                </div>
                            </div>
                        </div>
                        <b-table show-empty responsive :fields="contactFields" :items="contactItems" hover
                            :current-page="currentContactPage">
                            <template #cell(role)="data">
                                <p class="mb-0">{{ data.value ? data.value : '-' }}</p>
                            </template>
                            <template #cell(phone)="data">
                                <p class="mb-0">{{ data.value ? data.value : '-' }}</p>
                            </template>
                            <template #cell(email)="data">
                                <p class="mb-0">{{ data.value ? data.value : '-' }}</p>
                            </template>
                            <template #cell(action)="data">
                                <svg @click="setContactData(data.item)" class="mr-4 cursor-pointer" width="21" height="21"
                                    viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.9452 17.9664L1.71484 19.7109L3.45934 13.4806L14.8678 2.12751C14.9967 1.99564 15.1508 1.89086 15.3207 1.81932C15.4907 1.74779 15.6733 1.71094 15.8578 1.71094C16.0422 1.71094 16.2248 1.74779 16.3948 1.81932C16.5647 1.89086 16.7188 1.99564 16.8477 2.12751L19.2983 4.59196C19.428 4.72066 19.531 4.87381 19.6014 5.04251C19.6717 5.21123 19.7078 5.3922 19.7078 5.57497C19.7078 5.75774 19.6717 5.93871 19.6014 6.10743C19.531 6.27615 19.428 6.42928 19.2983 6.55798L7.9452 17.9664Z"
                                        stroke="#2CCE89" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                                <svg v-if="allowUpdateRecord" @click="setDeleteContactData(data.item.id)"
                                    class="cursor-pointer" width="19" height="20" viewBox="0 0 19 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5.15704H17.6139" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path
                                        d="M3.0766 5.15704H15.537V17.6175C15.537 17.9846 15.3911 18.3368 15.1315 18.5964C14.8719 18.856 14.5197 19.0019 14.1525 19.0019H4.46109C4.0939 19.0019 3.74175 18.856 3.4821 18.5964C3.22247 18.3368 3.0766 17.9846 3.0766 17.6175V5.15704Z"
                                        stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path
                                        d="M5.84607 5.15347V4.46123C5.84607 3.54325 6.21073 2.66287 6.85984 2.01377C7.50894 1.36466 8.38932 1 9.3073 1C10.2253 1 11.1057 1.36466 11.7548 2.01377C12.4039 2.66287 12.7685 3.54325 12.7685 4.46123V5.15347"
                                        stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M7.23175 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M11.385 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </template>
                        </b-table>
                    </v-tab-item>
                    <v-tab-item key='documents'>
                        <div class="d-block body">
                            <div class="mb-3 d-flex align-items-center justify-content-between px-4 mt-4rem">
                                <h3 class="f-18 font-weight-bold text-left px-0 mb-0">Documents</h3>
                                <div class="d-flex align-items-center justify-content-end cursor-pointer"
                                    @click="$bvModal.show('modal-document')" v-if="allowUpdateRecord">
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.5 12V6.5V12ZM6.5 6.5V1V6.5ZM6.5 6.5H12H6.5ZM6.5 6.5H1H6.5Z"
                                            fill="#367BF6" />
                                        <path d="M6.5 12V6.5M6.5 6.5V1M6.5 6.5H12M6.5 6.5H1" stroke="#367BF6"
                                            stroke-width="1.5" stroke-linecap="round" />
                                    </svg>
                                    <p class="text_primary ml-2 mb-0 fw-600">Add Document</p>
                                </div>
                            </div>
                            <b-table show-empty responsive :fields="documentFields" :items="documentItems" hover
                                :current-page="currentDocumentPage">
                                <template #cell(description)="data">
                                    <v-app v-if="data.value">
                                        <v-tooltip top>
                                            <template #activator="{ on, attrs }">
                                                <span class="w-fit-content item-description text-left" v-on="on"
                                                    v-bind="attrs">{{ data.value }}</span>
                                            </template>{{ formatTooltipText(data.value) }}
                                        </v-tooltip>
                                    </v-app>
                                    <p v-else>-</p>
                                </template>
                                <template #cell(action)="data">
                                    <svg @click="setDocumentData(data.item)" class="cursor-pointer mr-4" width="20"
                                        height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18.6426 6.19707C18.8726 6.48384 19 6.85642 19 7.24273C19 7.62904 18.8726 8.00161 18.6426 8.28838C17.186 10.052 13.8704 13.4854 10 13.4854C6.12957 13.4854 2.81403 10.052 1.35741 8.28838C1.12735 8.00161 1 7.62904 1 7.24273C1 6.85642 1.12735 6.48384 1.35741 6.19707C2.81403 4.4335 6.12957 1 10 1C13.8704 1 17.186 4.4335 18.6426 6.19707Z"
                                            stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path
                                            d="M10.0007 10.0154C11.533 10.0154 12.7752 8.77323 12.7752 7.24091C12.7752 5.70859 11.533 4.4664 10.0007 4.4664C8.46839 4.4664 7.2262 5.70859 7.2262 7.24091C7.2262 8.77323 8.46839 10.0154 10.0007 10.0154Z"
                                            stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                    <svg @click="downloadDoc(data.item)" class="mr-4 cursor-pointer" width="20" height="20"
                                        viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.53943 11.3822L10.001 14.8437L13.4625 11.3822" stroke="#FFC800"
                                            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M10.0001 14.8497V5.15741" stroke="#FFC800" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path
                                            d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
                                            stroke="#FFC800" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                    <svg v-if="allowUpdateRecord" @click="setDeleteDocumentData(data.item.id)"
                                        class="cursor-pointer" width="19" height="20" viewBox="0 0 19 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 5.15704H17.6139" stroke="#FF0000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path
                                            d="M3.0766 5.15704H15.537V17.6175C15.537 17.9846 15.3911 18.3368 15.1315 18.5964C14.8719 18.856 14.5197 19.0019 14.1525 19.0019H4.46109C4.0939 19.0019 3.74175 18.856 3.4821 18.5964C3.22247 18.3368 3.0766 17.9846 3.0766 17.6175V5.15704Z"
                                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path
                                            d="M5.84607 5.15347V4.46123C5.84607 3.54325 6.21073 2.66287 6.85984 2.01377C7.50894 1.36466 8.38932 1 9.3073 1C10.2253 1 11.1057 1.36466 11.7548 2.01377C12.4039 2.66287 12.7685 3.54325 12.7685 4.46123V5.15347"
                                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path d="M7.23175 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M11.385 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </template>
                            </b-table>
                        </div>
                    </v-tab-item>
                    <v-tab-item key="notes">
                        <div class="mt-4rem">
                            <Notes :key="currentClaimData.id" :noteURL="'app/safety-claim/' + currentClaimData.id + '/note'"
                                :allowUpdate="allowUpdateRecord" />
                        </div>
                    </v-tab-item>
                    <v-tab-item key="activity">
                        <div class="mt-4rem mb-6rem" v-if="showActivitySection">
                            <ActivityLogs :record="currentClaimData.record_no" :object_id="currentClaimData.id"
                                :key="currentClaimData.id" />
                        </div>
                    </v-tab-item>
                </v-tabs-items>
                <div class="sidebar-footer position-fixed">
                    <div class="d-flex align-items-center justify-content-end">
                        <div class="text-right modal-footer border-0 p-0">
                            <b-button class="mx-0 h-40px w-100px m-0" @click="resetPopup()" variant="secondary"
                                :class="hasChanged && allowUpdateRecord ? 'mr-3' : ''">Close</b-button>
                            <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary" @click="onUpdateClaim()"
                                v-if="hasChanged && allowUpdateRecord">Save</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </b-sidebar>
        <Contacts @contactUpdated="getContactData" :id="claimID" :contactData="contactData" @popupClose="contactData = null"
            :addContact="addClaimContact" :updateContact="updateClaimContact" :allowUpdateRecord="allowUpdateRecord" />
        <DeleteModal v-if="showDeleteContactPopup" @confirm="deleteContactItem($event)" />
        <DeleteModal v-if="showDeleteDocumentPopup" @confirm="deleteDocumentItem($event)" />
        <DeleteModal v-if="showDeleteClaimModal" @confirm="deleteClaimItem($event)" />
        <Document v-if="currentClaimData && currentClaimData.record_no && claimPayload.driver" :id="claimID"
            :allowUpdateRecord="allowUpdateRecord" @documentsUpdated="getDocumentData" :documentItem="documentData"
            @popupClose="documentData = null" :mediaData="media" :mediaDataID="mediaID" :mediaDataType="mediaType"
            :addDoc="addClaimDoc" :updateDoc="updateClaimDoc" :allowVideo="true" :driver="this.driverOptions.filter(
                (item) => item.id === claimPayload.driver
            )[0].label" :recordNo="currentClaimData.record_no" :deleteDocItem="deleteDocumentItem" />
    </div>
</template>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapActions, mapGetters } from "vuex";
import DeleteModal from "@/components/DeleteModal.vue";
import Contacts from "./Contacts";
import Document from './Document.vue';
import ActivityLogs from "@/components/Shared/ActivityLogs.vue"
import { checkValueExists } from "../../services/helper";
import Notes from "../Shared/Notes";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import * as moment from "moment-timezone";

export default {
    components: { vSelect, DeleteModal, Document, Contacts, ActivityLogs, Notes, DatePicker },
    name: "EditClaim",
    props: ['claimID'],
    computed: {
        ...mapGetters([
            "userCompany",
            "allEquipments",
            "allDrivers",
            "currentClaimData",
            "allCountries",
            "rolePermissions"
        ]),
        showActivitySection() {
            return this.rolePermissions && this.rolePermissions['Activity Log'] && this.rolePermissions['Activity Log'][0].allow
        },
        allowUpdateRecord() {
            return this.rolePermissions && this.rolePermissions['Safety'] && this.rolePermissions['Safety'][1].allow
        },
        allowDeleteRecord() {
            return this.rolePermissions && this.rolePermissions['Safety'] && this.rolePermissions['Safety'][3].allow
        },
        hasChanged() {
            return Object.keys(this.claimPayload).filter(field => {
                if (typeof this.claimPayload[field] === 'object') return JSON.stringify(this.claimPayload[field]) !== JSON.stringify(this.defaultData[field])
                if (field === 'odometer' || field === 'amount') return this.checkValueExists(this.claimPayload[field])?.toString()?.replaceAll(",", "") != this.checkValueExists(this.defaultData[field])?.toString()?.replaceAll(",", "")
                if (field === 'notes') {
                    return this.checkValueExists(this.claimPayload[field]?.trim()?.replace(/\r\n|\r|\n/g, '\n')) != this.checkValueExists(this.defaultData[field]?.trim()?.replace(/\r\n|\r|\n/g, '\n'));
                }
                else return this.checkValueExists(this.claimPayload[field]) != this.checkValueExists(this.defaultData[field])
            }).length > 0
        }
    },
    data() {
        return {
            errors: {},
            contactData: null,
            checkValueExists,
            documentData: null,
            states: [],
            media: null,
            docName: "",
            momentFormat: {
                stringify: (date) => {
                    return date ? moment(date).format('MMM D, YYYY') : ''
                },
                parse: (value) => {
                    return value ? moment(value, 'MMM D, YYYY').toDate() : null
                }
            },
            mediaID: null,
            mediaType: null,
            currentDocumentPage: 1,
            showDeleteClaimModal: false,
            showDeleteContactPopup: false,
            showDeleteDocumentPopup: false,
            activeTab: "info",
            userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            currentContactPage: 1,
            claimPayload: {
                address: "",
                record_no: "",
                city: "",
                claim_number: "",
                postal_code: "",
                state: null,
                driver: null,
                status: "Closed",
                vehicle: null,
                trailer: null,
                date: null,
                time: null,
                notes: "",
                type: "",
                policy_type: "",
                insurance_name: "",
                damage_value: "",
                accident_number: "",
                customer: "",
                load_number: ""
            },
            contactFields: [
                {
                    key: "name",
                    label: "Name",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "role",
                    label: "Role",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "phone",
                    label: "Phone",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "email",
                    label: "Email",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
            ],

            driverOptions: [],
            vehicleOptions: [],
            defaultData: {},
            trailerOptions: [],
            contactItems: [],
            documentItems: [],
            accidentOptions: [],
            documentFields: [
                {
                    label: "Description",
                    key: "description",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
            ],
        }
    },
    methods: {
        ...mapActions([
            "getAllEquipments",
            "getAllDrivers",
            "setLoader",
            "getClaimData",
            "setNotification",
            "getAllCountries",
            "updateClaim",
            "getClaimContacts",
            "getClaimDocs",
            "deleteClaimDocument",
            "addClaimDoc",
            "updateClaimDoc",
            "addClaimContact",
            "updateClaimContact",
            "deleteClaim",
            "deleteClaimContact",
            "getAllAccidentData"
        ]),
        async openDoc(data) {
            this.docName = data.name
            this.media = data.document
            this.mediaID = data.id
            if (data.document.includes('.pdf')) this.mediaType = 'pdf'
            else if (data.document.includes('.mp4') || data.document.includes('.mkv') || data.document.includes('.mov') || data.document.includes('.avi')) this.mediaType = 'video'
            else this.mediaType = 'image'
        },
        downloadDoc(data) {
            this.docName = data.name
            this.setLoader(true)
            fetch(data.document)
                .then(response => response.blob())
                .then(blob => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${this.docName}.${data.document.split('.').pop().split(/[#?]/)[0]}`
                    link.click();
                    link.remove()
                    this.setLoader(false)
                })
                .catch(this.setLoader(false));
        },
        async deleteClaimItem(status) {
            if (status) {
                try {
                    await this.deleteClaim({
                        claimID: `${this.claimID}/?company_id=${this.userCompany}`
                    })
                    await this.setNotification({
                        msg: `Claim Successfully Deleted`,
                        type: "success",
                        color: "green",
                    });
                    this.showDeleteClaimModal = false
                    this.$emit("claimUpdated", true)
                    this.resetPopup()
                }
                catch (e) {
                    console.log(e)
                }
            } else {
                this.showDeleteClaimModal = false
            }
        },
        async openLink(link) {
            window.open(link, "_blank");
        },
        setDeleteDocumentData(id) {
            this.showDeleteDocumentPopup = true
            this.documentID = id
        },
        setDocumentData(data) {
            this.documentData = data
            this.openDoc(data)
            this.$bvModal.show("modal-document");
        },
        formatTooltipText(text) {
            const maxLineLength = window.screen.width <= 767 ? 50 : 80;
            let formattedText = "";
            let lineLength = 0;

            text.split(' ').forEach(word => {
                if (lineLength + word.length + 1 > maxLineLength) {
                    formattedText += "\n";
                    lineLength = 0;
                }
                formattedText += (lineLength === 0 ? "" : " ") + word;
                lineLength += word.length + 1;
            });

            return formattedText;
        },
        async getDocumentData() {
            this.documentItems = []
            const data = await this.getClaimDocs({ id: this.claimID })
            data.sort((a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase())).map((item) => {
                this.documentItems.push({
                    id: item.id,
                    date: item.date_created,
                    description: item.description,
                    document: item.document,
                    name: `claim-${this.currentClaimData.record_no}-${item.description ? item.description : ''}`
                })
            })
        },
        async deleteDocumentItem(status, id = null) {
            if (status) {
                try {
                    this.setLoader(true)
                    await this.deleteClaimDocument({
                        claimID: this.claimID,
                        documentID: `${id ? id : this.documentID}/?company_id=${this.userCompany}`
                    })
                    await this.setNotification({
                        msg: `Document Successfully Deleted`,
                        type: "success",
                        color: "green",
                    });
                    this.getDocumentData()
                    this.documentID = null
                    this.showDeleteDocumentPopup = false
                    this.setLoader(false)
                }
                catch (e) {
                    console.log(e)
                    this.setLoader(false)
                }
            } else {
                this.documentID = null
                this.showDeleteDocumentPopup = false
            }
        },

        setDeleteContactData(id) {
            this.showDeleteContactPopup = true
            this.contactID = id
        },
        async deleteContactItem(status) {
            if (status) {
                try {
                    this.setLoader(true)
                    await this.deleteClaimContact({
                        claimID: this.claimID,
                        contactID: `${this.contactID}/?company_id=${this.userCompany}`
                    })
                    await this.setNotification({
                        msg: `Contact Successfully Deleted`,
                        type: "success",
                        color: "green",
                    });
                    this.getContactData()
                    this.contactID = null
                    this.showDeleteContactPopup = false
                    this.setLoader(false)
                }
                catch (e) {
                    this.setLoader(false)
                    console.log(e)
                }
            } else {
                this.contactID = null
                this.showDeleteContactPopup = false
            }
        },
        setContactData(data) {
            this.contactData = data
            this.$bvModal.show("modal-contact");
        },
        async onUpdateClaim() {
            try {
                const nullKeys = this.checkNullValues({
                    driver: this.claimPayload.driver,
                    date: this.claimPayload.date,
                });
                if (nullKeys.length === 0) {
                    this.setLoader(true)
                    await this.updateClaim({ id: this.claimID, payload: { ...this.claimPayload, accident_number: this.claimPayload.accident_number ? this.claimPayload.accident_number : '', date: moment(this.claimPayload.date).format('YYYY-MM-DD') } })
                    await this.setNotification({
                        msg: `Claim Successfully Updated`,
                        type: "success",
                        color: "green",
                    });
                    this.setLoader(false)
                    this.$emit("claimUpdated", true)
                    this.resetPopup()
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
                console.log(error)
                this.setLoader(false);
            }
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        async getCountries() {
            await this.getAllCountries();
            const countries = this.allCountries.map((item) => ({
                id: item.id,
                label: item.name,
            }));
            this.getStates(countries[0]);
        },
        getStates(selectedOption) {
            this.states = this.allCountries
                .filter((country) => country.id === selectedOption.id)[0]
                .state.map((item) => ({
                    id: item.id,
                    label: item.name,
                }));
            this.claimPayload.state = this.states.filter(
                (state) => state.label === "-"
            )[0].id;
        },
        async getContactData() {
            this.contactItems = []
            const data = await this.getClaimContacts({ id: this.claimID })
            data.map((item) => {
                this.contactItems.push({
                    id: item.id,
                    name: item.name,
                    role: item.role,
                    phone: item.phone,
                    email: item.email
                })
            })
        },
        resetPopup() {
            this.$emit("resetEditClaim", true)
        },
        async searchAccidents(searchText) {
            let options = []
            const url = `sort_field=record_no&search_field=${searchText}&sort_order=desc&page=1&page_size=10`
            const data = await this.getAllAccidentData(url);
            data.records.forEach((x) => {
                options.push({
                    id: x.record_no.toString(),
                    label: x.record_no.toString(),
                });
            });
            this.accidentOptions = this.accidentOptions.concat(options)
            this.accidentOptions = [...new Map(this.accidentOptions.map(item => [item['id'], item])).values()]
        },
        async searchDriver(searchText) {
            let options = []
            const url = `name=${searchText}&page=1&page_size=10`
            await this.getAllDrivers(url);
            this.allDrivers.forEach((x) => {
                options.push({
                    id: x.id,
                    label: `${x.user.first_name} ${x.user.last_name}`,
                });
            });
            this.driverOptions = this.driverOptions.concat(options)
            this.driverOptions = [...new Map(this.driverOptions.map(item => [item['id'], item])).values()]
        },
        async searchVehicle(searchText) {
            let options = [];
            const url = `unit_no=${searchText}&type=Vehicle&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
            await this.getAllEquipments(url);
            this.allEquipments.forEach((x) => {
                options.push({
                    id: x.id,
                    label: x.unit_no,
                });
            });
            this.vehicleOptions = this.vehicleOptions.concat(options)
            this.vehicleOptions = [...new Map(this.vehicleOptions.map(item => [item['id'], item])).values()]
        },
        async searchTrailer(searchText) {
            let options = [];
            const url = `unit_no=${searchText}&type=Trailer&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
            await this.getAllEquipments(url);
            this.allEquipments.forEach((x) => {
                options.push({
                    id: x.id,
                    label: x.unit_no,
                });
            });
            this.trailerOptions = this.trailerOptions.concat(options)
            this.trailerOptions = [...new Map(this.trailerOptions.map(item => [item['id'], item])).values()]
        },
    },
    watch: {
        async claimID() {
            if (this.claimID) {
                this.activeTab = "info"
                try {
                    this.setLoader(true)
                    this.claimPayload = {
                        address: "",
                        city: "",
                        claim_number: "",
                        postal_code: "",
                        record_no: "",
                        state: null,
                        driver: null,
                        status: "Closed",
                        vehicle: null,
                        trailer: null,
                        date: null,
                        time: null,
                        notes: "",
                        type: "",
                        policy_type: "",
                        insurance_name: "",
                        damage_value: "",
                        accident_number: "",
                        customer: "",
                        load_number: ""
                    },
                        this.errors = {}
                    this.defaultData = {}
                    this.driverOptions = [],
                        this.vehicleOptions = [],
                        this.trailerOptions = []
                    this.accidentOptions = []
                    await this.getCountries();
                    await this.getClaimData(this.claimID)
                    this.claimPayload = {
                        address: this.currentClaimData.address,
                        record_no: this.currentClaimData.record_no,
                        city: this.currentClaimData.city,
                        claim_number: this.currentClaimData.claim_number,
                        postal_code: this.currentClaimData.postal_code,
                        state: this.currentClaimData.state,
                        driver: this.currentClaimData.driver,
                        status: this.currentClaimData.status,
                        vehicle: this.currentClaimData.vehicle,
                        trailer: this.currentClaimData.trailer,
                        date: moment.tz(this.currentClaimData.date, this.userTimeZone).toDate(),
                        time: this.currentClaimData.time,
                        notes: this.currentClaimData.notes,
                        type: this.currentClaimData.type,
                        policy_type: this.currentClaimData.policy_type,
                        insurance_name: this.currentClaimData.insurance_name,
                        damage_value: this.currentClaimData.damage_value,
                        accident_number: this.currentClaimData.accident_number,
                        customer: this.currentClaimData.customer,
                        load_number: this.currentClaimData.load_number
                    }
                    this.defaultData = JSON.parse(JSON.stringify(this.claimPayload))
                    if (this.currentClaimData.driver) {
                        this.driverOptions.push({
                            id: this.currentClaimData.driver,
                            label: this.currentClaimData.driver_name,
                        })
                    }
                    if (this.currentClaimData.vehicle) {
                        this.vehicleOptions.push({
                            id: this.currentClaimData.vehicle,
                            label: this.currentClaimData.vehicle_name,
                        })
                    }
                    if (this.currentClaimData.trailer) {
                        this.trailerOptions.push({
                            id: this.currentClaimData.trailer,
                            label: this.currentClaimData.trailer_name,
                        })
                    }
                    if (this.currentClaimData.accident_number) {
                        this.accidentOptions.push({
                            id: this.currentClaimData.accident_number,
                            label: this.currentClaimData.accident_number,
                        })
                    }
                    this.getContactData()
                    this.getDocumentData()
                    this.setLoader(false)
                }
                catch (e) {
                    console.log(e)
                    this.setLoader(false)
                }
            }
        }
    }
}
</script>