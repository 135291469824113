<template>
    <div>
        <b-sidebar id="sidebar-backdrop" :backdrop-variant="'dark'" backdrop shadow :visible="!!inspectionID" :right="true"
            no-header-close no-close-on-backdrop no-close-on-esc>
            <template #header="{ }">
                <div class="d-flex align-items-center w-100 justify-content-between">
                    <div class="d-flex align-items-center w-100 mr-2">
                        <div class="text-nowrap" v-if="currentDotInspection">
                            {{ currentDotInspection.record_no }}
                        </div>
                        <!-- <b-button class="ml-5 pe-none d-flex justify-content-center align-items-center w-100px h-40px"
                            :class="inspectionPayload.status === 'New'
                                ? 'new-record'
                                : inspectionPayload.status === 'Urgent'
                                    ? 'urgent-record text-white'
                                    : inspectionPayload.status === 'Open' ? 'open-record' : 'inactive-equipment text-black'"
                            :variant="inspectionPayload.status === 'New' ? 'warning' : inspectionPayload.status === 'Urgent' ? 'danger' : inspectionPayload.status === 'Open' ? 'success' : 'light'">
                            <p class="mb-0">{{ inspectionPayload.status }}</p>
                        </b-button> -->
                    </div>
                    <b-dropdown id="dropdown-divider" text="text" class="load-dropdown mt-0" v-if="allowDeleteRecord">
                        <template slot="button-content">
                            <b-icon class="dots" icon="three-dots"></b-icon>
                        </template>
                        <b-dropdown-item-button @click="showDeleteInspectionModal = true">
                            <p class="text_secondary cursor-pointer mb-0 f-14">Delete</p>
                        </b-dropdown-item-button>
                    </b-dropdown>
                </div>
            </template>
            <div class="body" v-if="currentDotInspection">
                <div class="sidebar-tabs">
                    <div class="bg-white rounded">
                        <v-tabs class="team-tabs mb-0" v-model="activeTab">
                            <v-tab key='info'>Info</v-tab>
                            <v-tab key='volations'>Violations</v-tab>
                            <v-tab key='documents'>Documents</v-tab>
                            <v-tab key='notes'>Notes</v-tab>
                            <v-tab key='activity' v-if="showActivitySection">Activity</v-tab>
                        </v-tabs>
                    </div>
                </div>
                <v-tabs-items v-model="activeTab">
                    <v-tab-item key='info'>
                        <hr class="mt-0" />
                        <div class="d-block text-center mt-4rem">
                            <b-row class="align-items-center px-4">
                                <h3 class="f-18 font-weight-bold col-12 text-left">Basic</h3>
                                <b-col md="6" sm="12" class="mt-3">
                                    <b-form-group label="Record # :" class="text-left mb-0">
                                        <b-form-input v-model="currentDotInspection.record_no" disabled type="text"
                                            placeholder="Enter Record" class="unit" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <div class="text-left select_arrow ">
                                        <label class="state">Status</label>
                                        <b-form-select v-model="inspectionPayload.status" :disabled="!allowUpdateRecord"
                                            :options="[{
                                                value: 'New',
                                                text: 'New'
                                            },
                                            {
                                                value: 'Open',
                                                text: 'Open'
                                            },
                                            {
                                                value: 'Closed',
                                                text: 'Closed'
                                            },
                                            {
                                                value: 'Urgent',
                                                text: 'Urgent'
                                            }
                                            ]" />
                                    </div>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label for="inspection-edit-datepicker" class="state">Date:</label>
                                        <date-picker v-model="inspectionPayload.date" :formatter="momentFormat"
                            :range-separator="' - '" @clear="inspectionPayload.date = ''" placeholder="Select Date"
                            class="date-picker-custom" :teleport="'body'" :disabled="!allowUpdateRecord"></date-picker>
                                    <p v-if="errors && errors.date" class="field-error">
                                        {{ errors.date[0] }}
                                    </p>
                                </b-col>
                                <b-col md="3" sm="12" class="text-left mt-3">
                                    <label class="state">Start time:</label>
                                    <b-form-input type="time" class="f-12" v-model="inspectionPayload.start_time"
                                        :disabled="!allowUpdateRecord"></b-form-input>
                                </b-col>
                                <b-col md="3" sm="12" class="text-left mt-3">
                                    <label class="state">End time:</label>
                                    <b-form-input type="time" class="f-12" v-model="inspectionPayload.end_time"
                                        :disabled="!allowUpdateRecord"></b-form-input>
                                </b-col>
                                <b-col md="6" sm="12">
                                    <div class="text-left select_arrow mt-3">
                                        <label class="state">Driver:</label>
                                        <v-select class="bg_white w-100 f-12" v-model="inspectionPayload.driver"
                                            :disabled="!allowUpdateRecord" :options="driverOptions" item-value="id"
                                            item-text="label" :reduce="(option) => option.id" @search="searchDriver">
                                            <span slot="no-options">
                                                Search Driver ...
                                            </span>
                                        </v-select>
                                        <p v-if="errors && errors.driver" class="field-error">
                                            {{ errors.driver[0] }}
                                        </p>
                                    </div>
                                </b-col>
                                <b-col md="3" sm="12">
                                    <div class="text-left select_arrow mt-3">
                                        <label class="state">Vehicle:</label>
                                        <v-select class="bg_white w-100 f-12" :disabled="!allowUpdateRecord"
                                            v-model="inspectionPayload.vehicle" :options="vehicleOptions" item-value="id"
                                            item-text="label" :reduce="(option) => option.id" @search="searchVehicle">
                                            <span slot="no-options">
                                                Search Vehicle ...
                                            </span>
                                        </v-select>
                                    </div>
                                </b-col>
                                <b-col md="3" sm="12">
                                    <div class="text-left select_arrow mt-3">
                                        <label class="state">Trailer:</label>
                                        <v-select class="bg_white w-100 f-12" :disabled="!allowUpdateRecord"
                                            v-model="inspectionPayload.trailer" :options="trailerOptions" item-value="id"
                                            item-text="label" :reduce="(option) => option.id" @search="searchTrailer">
                                            <span slot="no-options">
                                                Search Trailer ...
                                            </span>
                                        </v-select>
                                    </div>
                                </b-col>
                                <b-col lg="6" md="12">
                                    <div class="text-left select_arrow mt-3">
                                        <label class="state">Level:</label>
                                        <b-form-select v-model="inspectionPayload.level" :disabled="!allowUpdateRecord"
                                            class="f-12" :options="[{
                                                value: null,
                                                text: ''
                                            },
                                            {
                                                value: 'I',
                                                text: '|'
                                            },
                                            {
                                                value: 'II',
                                                text: '||'
                                            },
                                            {
                                                value: 'III',
                                                text: '|||'
                                            }
                                            ]" />
                                    </div>
                                </b-col>
                                <b-col md="3" sm="12" class="text-left mt-3">
                                    <label class="state">Report #:</label>
                                    <b-form-input type="text" :disabled="!allowUpdateRecord"
                                        v-model="inspectionPayload.report_number" class="f-12"
                                        placeholder="Enter Report #"></b-form-input>
                                </b-col>
                                <b-col md="3" sm="12">
                                    <div class="text-left select_arrow mt-3">
                                        <label class="state">State:</label>
                                        <v-select class="bg_white w-100" :disabled="!allowUpdateRecord"
                                            v-model="inspectionPayload.state" :options="states" item-value="id"
                                            item-text="label" :reduce="(option) => option.id">
                                        </v-select>
                                    </div>
                                </b-col>
                                <b-col md="12" class="text-left mb-7rem mt-3">
                                    <label class="state text-left">Description:</label>
                                    <b-form-textarea id="textarea" :disabled="!allowUpdateRecord" placeholder="Enter Notes"
                                        class="f-12" rows="5" v-model="inspectionPayload.notes"></b-form-textarea>
                                </b-col>
                            </b-row>
                        </div>
                    </v-tab-item>
                    <v-tab-item key='violations'>
                        <div class="d-block body">
                            <div class="mb-3 d-flex align-items-center justify-content-between px-4 mt-4rem">
                                <h3 class="f-18 font-weight-bold text-left px-0 mb-0">Violations</h3>
                                <div class="d-flex align-items-center justify-content-end cursor-pointer"
                                    @click='$bvModal.show("modal-violation")' v-if="allowUpdateRecord">
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.5 12V6.5V12ZM6.5 6.5V1V6.5ZM6.5 6.5H12H6.5ZM6.5 6.5H1H6.5Z"
                                            fill="#367BF6" />
                                        <path d="M6.5 12V6.5M6.5 6.5V1M6.5 6.5H12M6.5 6.5H1" stroke="#367BF6"
                                            stroke-width="1.5" stroke-linecap="round" />
                                    </svg>
                                    <p class="text_primary ml-2 mb-0 fw-600">Add Violation</p>
                                </div>
                            </div>
                            <b-table show-empty responsive :fields="violationFields" :items="violationItems" hover
                                :current-page="currentViolationPage">
                                <template #cell(weight)="data">
                                    <p class="mb-0">{{ data.value ? data.value : '-' }}</p>
                                </template>
                                <template #cell(points)="data">
                                    <p class="mb-0">{{ data.value ? data.value : '-' }}</p>
                                </template>
                                <template #cell(unit)="data">
                                    <p class="mb-0">{{ data.value ? data.value : '-' }}</p>
                                </template>
                                <template #cell(description)="data">
                                    <v-app v-if="data.value">
                                        <v-tooltip top>
                                            <template #activator="{ on, attrs }">
                                                <span class="w-fit-content item-description text-left mw-100px" v-on="on"
                                                    v-bind="attrs">{{ data.value }}</span>
                                            </template>{{ formatTooltipText(data.value) }}
                                        </v-tooltip>
                                    </v-app>
                                    <p v-else>-</p>
                                </template>
                                <template #cell(oos_status)="data">
                                    <b-form-checkbox v-model="data.item.oos_status" @change="updateViolationData(data.item)"
                                        name="check-button" switch size="lg" class="ml-2" />
                                </template>
                                <template #cell(action)="data">
                                    <svg @click="setViolationData(data.item)" class="mr-4 cursor-pointer" width="21"
                                        height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M7.9452 17.9664L1.71484 19.7109L3.45934 13.4806L14.8678 2.12751C14.9967 1.99564 15.1508 1.89086 15.3207 1.81932C15.4907 1.74779 15.6733 1.71094 15.8578 1.71094C16.0422 1.71094 16.2248 1.74779 16.3948 1.81932C16.5647 1.89086 16.7188 1.99564 16.8477 2.12751L19.2983 4.59196C19.428 4.72066 19.531 4.87381 19.6014 5.04251C19.6717 5.21123 19.7078 5.3922 19.7078 5.57497C19.7078 5.75774 19.6717 5.93871 19.6014 6.10743C19.531 6.27615 19.428 6.42928 19.2983 6.55798L7.9452 17.9664Z"
                                            stroke="#2CCE89" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                    <svg v-if="allowUpdateRecord" @click="setDeleteViolationData(data.item.id)"
                                        class="cursor-pointer" width="19" height="20" viewBox="0 0 19 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 5.15704H17.6139" stroke="#FF0000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path
                                            d="M3.0766 5.15704H15.537V17.6175C15.537 17.9846 15.3911 18.3368 15.1315 18.5964C14.8719 18.856 14.5197 19.0019 14.1525 19.0019H4.46109C4.0939 19.0019 3.74175 18.856 3.4821 18.5964C3.22247 18.3368 3.0766 17.9846 3.0766 17.6175V5.15704Z"
                                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path
                                            d="M5.84607 5.15347V4.46123C5.84607 3.54325 6.21073 2.66287 6.85984 2.01377C7.50894 1.36466 8.38932 1 9.3073 1C10.2253 1 11.1057 1.36466 11.7548 2.01377C12.4039 2.66287 12.7685 3.54325 12.7685 4.46123V5.15347"
                                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path d="M7.23175 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M11.385 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </template>
                            </b-table>
                        </div>
                    </v-tab-item>
                    <v-tab-item key='documents'>
                        <div class="d-block body">
                            <div class="mb-3 d-flex align-items-center justify-content-between px-4 mt-4rem">
                                <h3 class="f-18 font-weight-bold text-left px-0 mb-0">Documents</h3>
                                <div class="d-flex align-items-center justify-content-end cursor-pointer"
                                    v-if="allowUpdateRecord" @click="$bvModal.show('modal-document')">
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.5 12V6.5V12ZM6.5 6.5V1V6.5ZM6.5 6.5H12H6.5ZM6.5 6.5H1H6.5Z"
                                            fill="#367BF6" />
                                        <path d="M6.5 12V6.5M6.5 6.5V1M6.5 6.5H12M6.5 6.5H1" stroke="#367BF6"
                                            stroke-width="1.5" stroke-linecap="round" />
                                    </svg>
                                    <p class="text_primary ml-2 mb-0 fw-600">Add Document</p>
                                </div>
                            </div>
                            <b-table show-empty responsive :fields="documentFields" :items="documentItems" hover
                                :current-page="currentDocumentPage">
                                <template #cell(description)="data">
                                    <v-app v-if="data.value">
                                        <v-tooltip top>
                                            <template #activator="{ on, attrs }">
                                                <span class="w-fit-content item-description text-left" v-on="on"
                                                    v-bind="attrs">{{ data.value }}</span>
                                            </template>{{ formatTooltipText(data.value) }}
                                        </v-tooltip>
                                    </v-app>
                                    <p v-else>-</p>
                                </template>
                                <template #cell(action)="data">
                                    <svg @click="setDocumentData(data.item)" class="cursor-pointer mr-4" width="20"
                                        height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18.6426 6.19707C18.8726 6.48384 19 6.85642 19 7.24273C19 7.62904 18.8726 8.00161 18.6426 8.28838C17.186 10.052 13.8704 13.4854 10 13.4854C6.12957 13.4854 2.81403 10.052 1.35741 8.28838C1.12735 8.00161 1 7.62904 1 7.24273C1 6.85642 1.12735 6.48384 1.35741 6.19707C2.81403 4.4335 6.12957 1 10 1C13.8704 1 17.186 4.4335 18.6426 6.19707Z"
                                            stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path
                                            d="M10.0007 10.0154C11.533 10.0154 12.7752 8.77323 12.7752 7.24091C12.7752 5.70859 11.533 4.4664 10.0007 4.4664C8.46839 4.4664 7.2262 5.70859 7.2262 7.24091C7.2262 8.77323 8.46839 10.0154 10.0007 10.0154Z"
                                            stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                    <svg @click="downloadDoc(data.item)" class="mr-4 cursor-pointer" width="20" height="20"
                                        viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.53943 11.3822L10.001 14.8437L13.4625 11.3822" stroke="#FFC800"
                                            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M10.0001 14.8497V5.15741" stroke="#FFC800" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path
                                            d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
                                            stroke="#FFC800" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                    <svg v-if="allowUpdateRecord" @click="setDeleteDocumentData(data.item.id)"
                                        class="cursor-pointer" width="19" height="20" viewBox="0 0 19 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 5.15704H17.6139" stroke="#FF0000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path
                                            d="M3.0766 5.15704H15.537V17.6175C15.537 17.9846 15.3911 18.3368 15.1315 18.5964C14.8719 18.856 14.5197 19.0019 14.1525 19.0019H4.46109C4.0939 19.0019 3.74175 18.856 3.4821 18.5964C3.22247 18.3368 3.0766 17.9846 3.0766 17.6175V5.15704Z"
                                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path
                                            d="M5.84607 5.15347V4.46123C5.84607 3.54325 6.21073 2.66287 6.85984 2.01377C7.50894 1.36466 8.38932 1 9.3073 1C10.2253 1 11.1057 1.36466 11.7548 2.01377C12.4039 2.66287 12.7685 3.54325 12.7685 4.46123V5.15347"
                                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path d="M7.23175 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M11.385 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </template>
                            </b-table>
                        </div>
                    </v-tab-item>
                    <v-tab-item key="notes">
                        <div class="mt-4rem">
                            <Notes :key="currentDotInspection.id" :noteURL="'app/safety-inspection/' + currentDotInspection.id + '/note'"
                                :allowUpdate="allowUpdateRecord" />
                        </div>
                    </v-tab-item>
                    <v-tab-item key="activity">
                        <div class="mt-4rem mb-6rem" v-if="showActivitySection">
                            <ActivityLogs :record="currentDotInspection.record_no" :object_id="currentDotInspection.id" :key="currentDotInspection.id"/>
                        </div>
                    </v-tab-item>
                </v-tabs-items>
                <div class="sidebar-footer position-fixed">
                    <div class="d-flex align-items-center justify-content-end">
                        <div class="text-right modal-footer border-0 p-0">
                            <b-button class="mx-0 h-40px w-100px m-0" @click="resetPopup()" variant="secondary"
                                :class="hasChanged && allowUpdateRecord ? 'mr-3' : ''">Close</b-button>
                            <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary" @click="onUpdateInspection()"
                                v-if="hasChanged && allowUpdateRecord">Save</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </b-sidebar>
        <Violation @violationUpdated="getViolationData" :inspectionID="inspectionID" :violationData="violationData"
            @popupClose="violationData = null" :allowUpdateRecord="allowUpdateRecord" />
        <DeleteModal v-if="showDeleteViolationPopup" @confirm="deleteViolationItem($event)" />
        <DeleteModal v-if="showDeleteInspectionModal" @confirm="deleteInspectionItem($event)" />
        <DeleteModal v-if="showDeleteDocumentPopup" @confirm="deleteDocumentItem($event)" />
        <Document v-if="currentDotInspection && currentDotInspection.record_no && inspectionPayload.driver"
            :allowUpdateRecord="allowUpdateRecord" :id="inspectionID" @documentsUpdated="getDocumentData"
            :documentItem="documentData" :mediaData="media" :mediaDataID="mediaID" :mediaDataType="mediaType"
            @popupClose="documentData = null" :addDoc="addDotInspectionDoc" :updateDoc="updateDotInspectionDoc"
            :allowVideo="false" :driver="this.driverOptions.filter(
                (item) => item.id === inspectionPayload.driver
            )[0].label" :recordNo="currentDotInspection.record_no" :deleteDocItem="deleteDocumentItem" />
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import * as moment from "moment-timezone";
import Violation from './Violation.vue';
import DeleteModal from "@/components/DeleteModal.vue";
import Document from './Document.vue';
import ActivityLogs from "@/components/Shared/ActivityLogs.vue"
import { checkValueExists } from '../../services/helper';
import Notes from "../Shared/Notes";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
    name: "EditInspection",
    props: ['inspectionID'],
    components: { vSelect, Violation, DeleteModal, Document, ActivityLogs, Notes, DatePicker },
    data() {
        return {
            checkValueExists,
            showAdditionalSetting: false,
            violationID: null,
            defaultData: {},
            momentFormat: {
                stringify: (date) => {
                    return date ? moment(date).format('MMM D, YYYY') : ''
                },
                parse: (value) => {
                    return value ? moment(value, 'MMM D, YYYY').toDate() : null
                }
            },
            activeTab: "info",
            states: [],
            documentID: null,
            showDeleteViolationPopup: false,
            showDeleteDocumentPopup: false,
            errors: {},
            showDeleteInspectionModal: false,
            mediaID: null,
            docName: "",
            media: null,
            mediaType: null,
            violationData: null,
            driverOptions: [],
            vehicleOptions: [],
            trailerOptions: [],
            violationItems: [],
            documentItems: [],
            documentData: null,
            currentDocumentPage: 1,
            currentViolationPage: 1,
            violationFields: [
                {
                    key: "code",
                    label: "Code",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "description",
                    label: "Description",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "weight",
                    label: "Weight",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "points",
                    label: "Points",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "oos_status",
                    label: "OOS",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    label: "Unit",
                    key: "unit",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
            ],
            userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            documentFields: [
                {
                    label: "Description",
                    key: "description",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
            ],
            inspectionPayload: {
                driver: null,
                record_no: null,
                status: "Closed",
                vehicle: null,
                trailer: null,
                date: null,
                start_time: null,
                end_time: null,
                level: null,
                state: "",
                report_number: '',
                notes: ""
            },
        }
    },
    computed: {
        ...mapGetters(['currentDotInspection', 'allEquipments', 'allDrivers', 'userCompany', "allCountries", 'rolePermissions']),
        showActivitySection() {
            return this.rolePermissions && this.rolePermissions['Activity Log'] && this.rolePermissions['Activity Log'][0].allow
        },
        allowUpdateRecord() {
            return this.rolePermissions && this.rolePermissions['Safety'] && this.rolePermissions['Safety'][1].allow
        },
        allowDeleteRecord() {
            return this.rolePermissions && this.rolePermissions['Safety'] && this.rolePermissions['Safety'][3].allow
        },
        hasChanged() {
            return Object.keys(this.inspectionPayload).filter(field => {
                if (typeof this.inspectionPayload[field] === 'object') return JSON.stringify(this.inspectionPayload[field]) !== JSON.stringify(this.defaultData[field])
                if (field === 'odometer' || field === 'amount') return this.checkValueExists(this.inspectionPayload[field])?.toString()?.replaceAll(",", "") != this.checkValueExists(this.defaultData[field])?.toString()?.replaceAll(",", "")
                if (field === 'notes') {
                    return this.checkValueExists(this.inspectionPayload[field]?.trim()?.replace(/\r\n|\r|\n/g, '\n')) != this.checkValueExists(this.defaultData[field]?.trim()?.replace(/\r\n|\r|\n/g, '\n'));
                }
                else return this.checkValueExists(this.inspectionPayload[field]) != this.checkValueExists(this.defaultData[field])
            }).length > 0
        }
    },
    methods: {
        ...mapActions(['getAllCountries', 'getDotInspection', "setLoader", 'getAllEquipments', 'getAllDrivers', 'getAllViolations', 'deleteViolation', "setNotification", "setLoader", "updateDotInspection", "deleteDotInspection", "getDotInspectionDocs", "updateViolation", "deleteDotInspectionDocument", 'addDotInspectionDoc', 'updateDotInspectionDoc', 'retrieveDocument']),
        async openLink(link) {
            window.open(link, "_blank");
        },
        async openDoc(data) {
            this.docName = data.name
            this.media = data.document
            this.mediaID = data.id
            if (data.document.includes('.pdf')) this.mediaType = 'pdf'
            else if (data.document.includes('.mp4') || data.document.includes('.mkv') || data.document.includes('.mov') || data.document.includes('.avi')) this.mediaType = 'video'
            else this.mediaType = 'image'
        },
        downloadDoc(data) {
            this.docName = data.name
            this.setLoader(true)
            fetch(data.document)
                .then(response => response.blob())
                .then(blob => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${this.docName}.${data.document.split('.').pop().split(/[#?]/)[0]}`
                    link.click();
                    link.remove()
                    this.setLoader(false)
                })
                .catch(this.setLoader(false));
        },
        formatTooltipText(text) {
            const maxLineLength = window.screen.width <= 767 ? 50 : 80;
            let formattedText = "";
            let lineLength = 0;

            text.split(' ').forEach(word => {
                if (lineLength + word.length + 1 > maxLineLength) {
                    formattedText += "\n";
                    lineLength = 0;
                }
                formattedText += (lineLength === 0 ? "" : " ") + word;
                lineLength += word.length + 1;
            });

            return formattedText;
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        async onUpdateInspection() {
            try {
                const nullKeys = this.checkNullValues({
                    driver: this.inspectionPayload.driver,
                    date: this.inspectionPayload.date,
                });
                if (nullKeys.length === 0) {
                    this.setLoader(true)
                    await this.updateDotInspection({ id: this.inspectionID, payload: {...this.inspectionPayload, date: moment(this.inspectionPayload.date).format('YYYY-MM-DD')} })
                    await this.setNotification({
                        msg: `Dot Inspection Successfully Updated`,
                        type: "success",
                        color: "green",
                    });
                    this.setLoader(false);
                    this.$emit("inspectionUpdated", true)
                    this.resetPopup()
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
                console.log(error)
                this.setLoader(false);
            }
        },
        setDeleteViolationData(id) {
            this.showDeleteViolationPopup = true
            this.violationID = id
        },
        setDeleteDocumentData(id) {
            this.showDeleteDocumentPopup = true
            this.documentID = id
        },
        setDocumentData(data) {
            this.documentData = data
            this.openDoc(data)
            this.$bvModal.show("modal-document");
        },
        async updateViolationData(data) {
            try {
                this.setLoader(true)
                await this.updateViolation({ id: this.inspectionID, payload: { ...data, id: data.id } })
                await this.setNotification({
                    msg: `Violation Successfully Updated`,
                    type: "success",
                    color: "green",
                });
                this.setLoader(false)
            }
            catch (e) {
                this.setLoader(false)
                console.log(e)
            }
        },
        setViolationData(data) {
            this.violationData = data
            this.$bvModal.show("modal-violation");
        },
        async deleteInspectionItem(status) {
            if (status) {
                try {
                    this.setLoader(true)
                    await this.deleteDotInspection({
                        inspectionID: `${this.inspectionID}/?company_id=${this.userCompany}`
                    })
                    await this.setNotification({
                        msg: `Dot Inspection Successfully Deleted`,
                        type: "success",
                        color: "green",
                    });
                    this.showDeleteInspectionModal = false
                    this.setLoader(false)
                    this.$emit("inspectionUpdated", true)
                    this.resetPopup()
                }
                catch (e) {
                    this.setLoader(false)
                    console.log(e)
                }
            } else {
                this.showDeleteInspectionModal = false
            }
        },
        async deleteViolationItem(status) {
            if (status) {
                try {
                    this.setLoader(true)
                    await this.deleteViolation({
                        inspectionID: this.inspectionID,
                        violationID: `${this.violationID}/?company_id=${this.userCompany}`
                    })
                    await this.setNotification({
                        msg: `Violation Successfully Deleted`,
                        type: "success",
                        color: "green",
                    });
                    this.getViolationData()
                    this.violationID = null
                    this.showDeleteViolationPopup = false
                    this.setLoader(false)
                }
                catch (e) {
                    this.setLoader(false)
                    console.log(e)
                }
            } else {
                this.violationID = null
                this.showDeleteViolationPopup = false
            }
        },
        async deleteDocumentItem(status, id = null) {
            if (status) {
                try {
                    this.setLoader(true)
                    await this.deleteDotInspectionDocument({
                        inspectionID: this.inspectionID,
                        documentID: `${id ? id : this.documentID}/?company_id=${this.userCompany}`
                    })
                    await this.setNotification({
                        msg: `Document Successfully Deleted`,
                        type: "success",
                        color: "green",
                    });
                    this.getDocumentData()
                    this.documentID = null
                    this.showDeleteDocumentPopup = false
                    this.setLoader(false)
                }
                catch (e) {
                    this.setLoader(false)
                    console.log(e)
                }
            } else {
                this.documentID = null
                this.showDeleteDocumentPopup = false
            }
        },

        resetPopup() {
            this.$emit("resetEditInspection", true)
        },
        async searchVehicle(searchText) {
            let options = [];
            const url = `unit_no=${searchText}&type=Vehicle&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
            await this.getAllEquipments(url);
            this.allEquipments.forEach((x) => {
                options.push({
                    id: x.id,
                    label: x.unit_no,
                });
            });
            this.vehicleOptions = this.vehicleOptions.concat(options)
            this.vehicleOptions = [...new Map(this.vehicleOptions.map(item => [item['id'], item])).values()]
        },
        async searchDriver(searchText) {
            let options = []
            const url = `name=${searchText}&page=1&page_size=10`
            await this.getAllDrivers(url);
            this.allDrivers.forEach((x) => {
                options.push({
                    id: x.id,
                    label: `${x.user.first_name} ${x.user.last_name}`,
                });
            });
            this.driverOptions = this.driverOptions.concat(options)
            this.driverOptions = [...new Map(this.driverOptions.map(item => [item['id'], item])).values()]
        },
        async searchTrailer(searchText) {
            let options = [];
            const url = `unit_no=${searchText}&type=Trailer&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
            await this.getAllEquipments(url);
            this.allEquipments.forEach((x) => {
                options.push({
                    id: x.id,
                    label: x.unit_no,
                });
            });
            this.trailerOptions = this.trailerOptions.concat(options)
            this.trailerOptions = [...new Map(this.trailerOptions.map(item => [item['id'], item])).values()]
        },
        async getViolationData() {
            this.violationItems = []
            const data = await this.getAllViolations({ id: this.inspectionID })
            data.map((item) => {
                this.violationItems.push({
                    id: item.id,
                    code: item.code,
                    description: item.description,
                    weight: item.weight,
                    points: item.points,
                    oos_status: item.oos_status,
                    unit: item.unit
                })
            })
        },
        async getDocumentData() {
            this.documentItems = []
            const data = await this.getDotInspectionDocs({ id: this.inspectionID })
            data.sort((a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase())).map((item) => {
                this.documentItems.push({
                    id: item.id,
                    date: item.date_created,
                    description: item.description,
                    document: item.document,
                    name: `dot inspection-${this.currentDotInspection.record_no}-${item.description ? item.description : ''}`
                })
            })
        },
        async getCountries() {
            await this.getAllCountries();
            const countries = this.allCountries.map((item) => ({
                id: item.id,
                label: item.name,
            }));
            this.getStates(countries[0]);
        },
        getStates(selectedOption) {
            this.states = this.allCountries
                .filter((country) => country.id === selectedOption.id)[0]
                .state.map((item) => ({
                    id: item.id,
                    label: item.name,
                }));
            this.inspectionPayload.state = this.states.filter(
                (state) => state.label === "-"
            )[0].id;
        }
    },
    watch: {
        async inspectionID() {
            if (this.inspectionID) {
                this.activeTab = 'info'
                try {
                    this.setLoader(true)
                    this.inspectionPayload = {
                        driver: null,
                        record_no: null,
                        status: "Closed",
                        vehicle: null,
                        trailer: null,
                        date: null,
                        start_time: null,
                        end_time: null,
                        level: null,
                        state: "",
                        report_number: '',
                        notes: ""
                    }
                    this.errors = {}
                    this.defaultData = {}
                    await this.getCountries();
                    await this.getDotInspection(this.inspectionID)
                    this.inspectionPayload = {
                        record_no: this.currentDotInspection.record_no,
                        driver: this.currentDotInspection.driver,
                        status: this.currentDotInspection.status,
                        vehicle: this.currentDotInspection.vehicle,
                        trailer: this.currentDotInspection.trailer,
                        date: moment.tz(this.currentDotInspection.date, this.userTimeZone).toDate(),
                        start_time: this.currentDotInspection.start_time,
                        end_time: this.currentDotInspection.end_time,
                        level: this.currentDotInspection.level,
                        state: this.currentDotInspection.state,
                        report_number: this.currentDotInspection.report_number,
                        notes: this.currentDotInspection.notes
                    }
                    this.defaultData = JSON.parse(JSON.stringify(this.inspectionPayload))
                    if (this.currentDotInspection.driver) {
                        this.driverOptions.push({
                            id: this.currentDotInspection.driver,
                            label: this.currentDotInspection.driver_name,
                        })
                    }
                    if (this.currentDotInspection.vehicle) {
                        this.vehicleOptions.push({
                            id: this.currentDotInspection.vehicle,
                            label: this.currentDotInspection.vehicle_name,
                        })
                    }
                    if (this.currentDotInspection.trailer) {
                        this.trailerOptions.push({
                            id: this.currentDotInspection.trailer,
                            label: this.currentDotInspection.trailer_name,
                        })
                    }
                    await this.getViolationData()
                    await this.getDocumentData()
                    this.setLoader(false)
                }
                catch (e) {
                    console.log(e)
                }
            }
        }
    }
}
</script>
<style>
.mw-100px {
    max-width: 100px !important;
}
</style>