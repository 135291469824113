<template>
    <div>
        <div class="profile-form bg-white p-3 rounded">
            <h5 class="font-weight-bold font-20 text-left">Password</h5>
            <b-form @submit.prevent="updatePassword">
                <b-row>
                    <b-col sm="8">
                        <b-form-group label="Old Password" label-for="password-3" class="text-left password mb-3">
                            <b-form-input :type="showPassword1 ? 'text' : 'password'" id="password-1"
                                placeholder="Enter Old Password" v-model="passwords.user.old_password"
                                required></b-form-input>
                            <b-icon :icon="showPassword1 ? 'eye-fill' : 'eye-slash-fill'"
                                @click="showPassword1 = !showPassword1" style="fill:black"></b-icon>
                        </b-form-group>
                    </b-col>
                    <b-col sm="8">
                        <b-form-group label="New Password" label-for="password-3" class="text-left password mb-3">
                            <b-form-input :type="showPassword2 ? 'text' : 'password'" id="password-2"
                                placeholder="Enter New Password" v-model="passwords.user.new_password"
                                required></b-form-input>
                            <b-icon :icon="showPassword2 ? 'eye-fill' : 'eye-slash-fill'"
                                @click="showPassword2 = !showPassword2" style="fill:black"></b-icon>
                        </b-form-group>
                    </b-col>
                    <b-col sm="8">
                        <b-form-group label="Confirm Password" label-for="password-3" class="text-left password mb-3">
                            <b-form-input :type="showPassword3 ? 'text' : 'password'" id="password-3"
                                placeholder="Enter Confirm Password" v-model="passwords.user.confirm_password"
                                required></b-form-input>
                            <b-icon :icon="showPassword3 ? 'eye-fill' : 'eye-slash-fill'"
                                @click="showPassword3 = !showPassword3" style="fill:black"></b-icon>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12">
                        <div class="d-flex align-items-center flex-wrap">
                            <b-button to="/" class="mx-0 h-40px w-100px mt-3 mr-3 m-0" variant="secondary">Close</b-button>
                            <b-button v-if="passwords.user.old_password && passwords.user.new_password && passwords.user.confirm_password" class="mt-3 save h-40px w-100px m-0" variant="primary" type="submit">Save</b-button>
                        </div>
                    </b-col>
                </b-row>
            </b-form>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "Password",
    data() {
        return {
            showPassword1: false,
            showPassword2: false,
            showPassword3: false,
            passwords: {
                user: {
                    old_password: null,
                    new_password: null,
                    confirm_password: null,
                },
            },
        }
    },
    computed: {
        ...mapGetters(['user'])
    },
    methods: {
        ...mapActions(['setNotification', 'updateTeamMember']),
        async updatePassword() {
            if (
                this.passwords.user.new_password === this.passwords.user.old_password
            ) {
                await this.setNotification({
                    msg: "Old Password can't be used as a New Password",
                    type: "error",
                    color: "red",
                });
            } else if (
                this.passwords.user.new_password ===
                this.passwords.user.confirm_password
            ) {
                try {
                    await this.updateTeamMember({
                        id: this.user.team_member.id,
                        payload: this.passwords,
                    });
                    await this.setNotification({
                        msg: "Successfully Password Updated!!",
                        type: "success",
                        color: "green",
                    });
                    this.passwords = {
                        user: {
                            old_password: null,
                            new_password: null,
                            confirm_password: null,
                        },
                    }
                } catch (error) {
                    await this.setNotification({
                        msg: error,
                        type: "error",
                        color: "red",
                    });
                }
            } else {
                await this.setNotification({
                    msg: "Password doesn't match.",
                    type: "error",
                    color: "red",
                });
            }
        },
    },
}
</script>
<style></style>